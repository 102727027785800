import * as PropTypes from 'prop-types'

export const UserProfilePropTypes = {
  contractCountry: PropTypes.string,
  empId: PropTypes.string,
  gdprAcceptance: PropTypes.bool,
  isCadre: PropTypes.bool,
  isHR: PropTypes.bool,
  isLoggedUserbadgeConsentAccepted: PropTypes.bool,
  isLoginUserBadgeAdmin: PropTypes.bool,
  isManager: PropTypes.bool,
  isOtherUser: PropTypes.bool,
  isSysAdmin: PropTypes.bool,
  isleaveRequestSetup: PropTypes.bool,
  navTo: PropTypes.string,
  publicProfile: PropTypes.bool,
}
export const targetPropTypes = {
  citrixId: PropTypes.string,
  email: PropTypes.string,
  isCommonUser: PropTypes.bool,
  defaultTarget: PropTypes.string,
  regionIdentifier: PropTypes.string,
  adfsUserInfo: PropTypes.any,
  additionalTargets: PropTypes.any,
  contractCountry: PropTypes.string,
  isInfluencer: PropTypes.bool,
  isWorkdayEnabled: PropTypes.bool,
}
export const uidPropTypes = {
  uid: PropTypes.string,
}
