/**
 * NotificationPopper - to display top 5 notification to user
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickShanmugam0689)
 */
import * as React from 'react'
import {graphql, compose, withApollo} from 'react-apollo'
import {
  getNotificationListQuery,
  saveAckNotificationMutation,
  getNotificationCountsQuery,
  getDrupalNotifications,
  readAllNotificationsMutation,
  getDrupalNotificationsCount,
} from '../../queries/notificationQuery'
import {getContext} from 'recompose'
import {targetPropTypes} from '../../commonPropsType'
import {IProps} from './NotificationPopper.d'
import SXTNotificationList from '../common/SXTNotification/SXTNotificationList'
import {NavLink} from 'react-router-dom'
import {INotification} from '../common/SXTNotification/SXTNotification.d'
import './NotificationPopper.scss'
import {checkObjProps} from '../../utils/genericUtils'
import Loader from '../common/Loader/Loader'
import moment from 'moment'
import DraftsIcon from '@material-ui/icons/Drafts'
import IconButton from '@material-ui/core/IconButton'
import {fetchDrupalNotifications} from './utils.Notifications'

const NOTIFICATIONS_ALLOWED_IN_POPPER = 5
const NOTIFICATION_FETCH_LIMIT = 25
const DRUPAL_HISTORY_MONTHS = 1

const getRequiredNotifications = (
  notificationList: INotification[] | null | undefined
) => {
  if (!notificationList?.length) return []
  return notificationList.slice(0, NOTIFICATIONS_ALLOWED_IN_POPPER)
}

const NotificationPopper = (props: IProps) => {
  const {
    error,
    isSForceLoading,
    isDrupalLoading,
    notificationList,
    acknowledgeNotification,
    citrixId,
    showMore,
    handleToggle,
    client,
    drupalNotifications,
    acknowledgedrupNotification,
  } = props

  const [notificationsToRead, setNotificationsToRead] = React.useState<
    INotification[]
  >([])
  const [isMerging, setIsMerging] = React.useState(false)

  const sortedNotifications = React.useMemo(() => {
    if (!drupalNotifications?.length) return notificationList || []

    try {
      const mentionsArray = fetchDrupalNotifications(drupalNotifications)

      const merged = [...mentionsArray, ...(notificationList || [])].sort(
        (e1, e2) => {
          const date1 = new Date(e1.submittedDate).getTime()
          const date2 = new Date(e2.submittedDate).getTime()
          return date2 - date1
        }
      )

      return merged
    } catch (err) {
      console.error('[NotificationPopper] Error merging notifications:', err)
      return notificationList || []
    }
  }, [notificationList, drupalNotifications])

  React.useEffect(() => {
    let mounted = true
    const updateNotifications = async () => {
      if (!mounted) return
      setIsMerging(true)
      try {
        const finalNotifications = getRequiredNotifications(sortedNotifications)
        setNotificationsToRead(finalNotifications)
      } catch (err) {
        console.error('[NotificationPopper] Error updating notifications:', err)
      } finally {
        setIsMerging(false)
      }
    }

    updateNotifications()
    return () => {
      mounted = false
    }
  }, [sortedNotifications])

  const markAllNotificationsAsRead = async () => {
    try {
      await acknowledgedrupNotification()
      await readAllSFNotifications()
      handleToggle(false)
    } catch (err) {
      console.error(
        '[NotificationPopper] Error marking notifications as read:',
        err
      )
    }
  }

  const readAllSFNotifications = () => {
    try {
      const notifications =
        notificationList?.map((eachNotification: INotification) => ({
          id: eachNotification.id,
          isRead: true,
        })) || []

      return acknowledgeNotification({
        variables: {
          citrixId,
          notifications: JSON.stringify(notifications),
        },
      })
    } catch (ex) {
      console.warn('[NotificationPopper] Error reading SF notifications:', ex)
      return null
    }
  }

  if (error) {
    return <div className="error-block">Error! {error.message}</div>
  }

  return (
    <div className="notification-popper">
      {isSForceLoading || isDrupalLoading || isMerging ? (
        <Loader isInContainer={true} />
      ) : (
        <>
          {notificationsToRead.length > 0 && (
            <IconButton
              className="mark-read"
              onClick={markAllNotificationsAsRead}
            >
              <DraftsIcon color="inherit" fontSize="small" />
              Mark all as read
            </IconButton>
          )}
          <SXTNotificationList
            notifications={notificationsToRead}
            isLighterView={true}
          />
          {(notificationsToRead.length > 4 || showMore) && (
            <NavLink
              to="/notification/all"
              className="view-all"
              onClick={() => handleToggle(false)}
            >
              View All
            </NavLink>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(
  compose(
    withApollo,
    getContext(targetPropTypes),
    graphql(getNotificationListQuery, {
      options: (props: any) => ({
        variables: {
          empId: props.citrixId,
          typeOfNotification: 'all',
          limit: NOTIFICATION_FETCH_LIMIT,
          offset: 0,
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      }),
      props: ({data: {error, loading, getNotificationList = {}}}: any) => {
        const notificationList = checkObjProps(
          getNotificationList,
          ['notifications'],
          []
        )
        const showMore = checkObjProps(getNotificationList, ['showMore'], false)
        return {
          error,
          isSForceLoading: loading,
          notificationList,
          showMore,
        }
      },
    }),
    graphql(saveAckNotificationMutation, {
      name: 'acknowledgeNotification',
      options: (props: any) => ({
        refetchQueries: [
          {
            query: getNotificationCountsQuery,
            variables: {empId: props.citrixId},
          },
          {
            query: getDrupalNotificationsCount,
          },
          {
            query: getNotificationListQuery,
            variables: {
              empId: props.citrixId,
              typeOfNotification: 'tasks',
              limit: 15,
              offset: 0,
            },
          },
          {
            query: getDrupalNotifications,
            variables: {
              since: moment().subtract(1, 'months').unix(),
            },
          },
        ],
      }),
    }),
    graphql(readAllNotificationsMutation, {
      name: 'acknowledgedrupNotification',
    }),
    graphql(getDrupalNotifications, {
      options: () => ({
        variables: {
          since: moment().subtract(DRUPAL_HISTORY_MONTHS, 'months').unix(),
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      }),
      props: ({data: {error, loading, notifications = []}}: any) => {
        return {
          error,
          isDrupalLoading: loading,
          drupalNotifications: notifications,
        }
      },
    })
  )(NotificationPopper)
)
