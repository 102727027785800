import {checkObjProps} from '../../utils/genericUtils'

const type = {
  field_wiki_comments: '/wiki/',
  field_thinktank_comments: '/think-tank/feed/',
  field_comment_news: '/news/',
  field_social_comments: '/socialnews/',
  field_airline_comments: '/airline/',
  field_hotel_comments: '/hotel/',
  default: '/node/',
}
export interface drupNotify {
  entityBundle: string
  author: {
    fieldFirstName: string
    fieldLastName: string
    name: string
  }
  stateRead: boolean
  entityId: string
  entityCreated: string
}
export const fetchDrupalNotifications = drupalNotifications => {
  const allNotifications = []

  drupalNotifications.forEach((item: drupNotify) => {
    switch (item.entityBundle) {
      case 'mention_created': {
        let redirectionURL = ''
        const mentionedEntityId = checkObjProps(item, [
          'sixtMention',
          'entity',
          'mentionOnEntityId',
          'entity',
          'entityIdOfComment',
          'entity',
          'entityId',
        ])
        if (mentionedEntityId) {
          redirectionURL =
            window.location.origin +
            type[
              checkObjProps(
                item,
                [
                  'sixtMention',
                  'entity',
                  'mentionOnEntityId',
                  'entity',
                  'fieldName',
                ],
                'default'
              )
            ] +
            mentionedEntityId
        }
        const notificationObj = {
          category: 'Sixtbook Tagged',
          description:
            '<b>' +
            item.author.fieldFirstName +
            ' ' +
            item.author.fieldLastName +
            '</b> mentioned you in a conversation',
          icon: null,
          isRead: item.stateRead || false,
          entityId: item.entityId,
          redirectionURL,
          submittedDate: item.entityCreated,
          submitterCitrixId: item.author.name,
          typeOfNotification: 'Content',
        }
        allNotifications.push(notificationObj)
        break
      }
      case 'comment_created:comment': {
        let redirectionURL = ''
        const mentionedEntityId = checkObjProps(item, [
          'comment',
          'entity',
          'entityIdOfComment',
          'entity',
          'entityId',
        ])
        if (mentionedEntityId) {
          redirectionURL =
            window.location.origin +
            type[
              checkObjProps(item, ['comment', 'entity', 'fieldName'], 'default')
            ] +
            mentionedEntityId
        }
        const notificationObj = {
          category: 'Sixtbook Tagged',
          description:
            '<b>' +
            item.author.fieldFirstName +
            ' ' +
            item.author.fieldLastName +
            '</b> commented on your content',
          icon: null,
          isRead: item.stateRead || false,
          entityId: item.entityId,
          redirectionURL,
          submittedDate: item.entityCreated,
          submitterCitrixId: item.author.name,
          typeOfNotification: 'Content',
        }
        allNotifications.push(notificationObj)
        break
      }
    }
  })
  return allNotifications
}
