import * as React from 'react'
import {setCookie, getLoginAsInfoFromCookie} from '../../../utils/cookieUtils'
import {Button} from '@material-ui/core'
import './CookieConsentWrapper.scss'

const cookieContent =
  'SIXTBOOK uses cookies to personalize content, evaluate our site, and provide you with the best possible service. By continuing to use our website, you agree to the use of cookies. Want to change this or learn more about our policy?'
const CookieConsentWrapper = (props: {citrixId: string}) => {
  const {citrixId} = props
  const [show, handleShow] = React.useState(false)
  const acceptCookies = () => {
    setCookie('sixtbook-tracking-consent', `${citrixId}--accepted`)
    window.gtmInit()
    handleShow(false)
  }
  React.useEffect(() => {
    const isUserConsentCookie = window.getCookieByName(
      'sixtbook-tracking-consent'
    )
    const canITrack = isUserConsentCookie.indexOf(`${citrixId}--accepted`) > -1
    const loginAsInfo = getLoginAsInfoFromCookie()
    if (canITrack) {
      window.gtmInit()
    } else {
      if (loginAsInfo && loginAsInfo.email) {
        handleShow(false)
      } else {
        handleShow(true)
      }
    }
  }, [citrixId])
  return (
    <>
      {show && (
        <div
          className="cookie-consent-banner"
          data-testid="cookie-consent-wrapper"
        >
          {cookieContent && (
            <div className="cookie-content">
              {cookieContent}
              <a target="blank" href="/cookies-consent">
                Please refer to our cookie policy here
              </a>
            </div>
          )}
          <div>
            <Button
              className="submit-btn"
              onClick={acceptCookies}
              data-testid="accept-ccokie"
            >
              Got it
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default React.memo(CookieConsentWrapper)
