/**
 * Logout =- Logout page for Sixtbook
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickshanmugam89)
 */
import * as React from 'react'
import {logoutClearTokens} from '../../utils/auth'
import {getLoginAsInfoFromCookie} from '../../utils/cookieUtils'
import {logoutFromLoginAs} from '../../utils/userUtils'

const Logout = () => {
  const loginAsUserInfo = getLoginAsInfoFromCookie()
  if (loginAsUserInfo.citrixId) {
    logoutFromLoginAs()
  } else {
    logoutClearTokens()
  }

  return <>You are going to be logged-out now.</>
}

export default Logout
