import * as React from 'react'
import SXTCountBadge from '../SXTCountBadge/SXTCountBadge'
import './notificationCountBadge.scss'

interface IProps {
  count: number
}

const NotificationCountBadge = (props: IProps) => {
  const {count} = props

  return (
    <div className="notification-count-badge">
      {count > 0 && (
        <SXTCountBadge
          cssClass={count > 99 ? 'min' : ''}
          count={count > 99 ? '99+' : count}
        />
      )}
    </div>
  )
}

export default React.memo(NotificationCountBadge)
