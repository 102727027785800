import gql from 'graphql-tag.macro'

export const employeeDetailsQuery = gql`
  query ($empId: Id) {
    employee(empId: $empId) @client {
      city
      citrixId
      country
      email
      firstName
      houseNumber
      jobTitle
      lastName
      legalEntityName
      personalMobile
      phone
      roomNumber
      state
      street
      workBuilding
      workMobile
      zipCode
      photoUrl
      dateFormat
      userLocale
      sxClient
      sxRegion
      costCenterName
      costCenterCode
      publicProfile
      gdprAcceptance
      isbadgeConsentAccepted
      isLoginUserBadgeAdmin
      isManager
      isHR
      contractCountry
      isCadre
      externalEmail
      countryCode
      leaveRequestSetup
      isSysAdmin
      sfUserName
      isLeasingEmployee
      isSixtbookSocialFeedAdmin
      additionalTargetRegions
    }
    email @client
    name @client
    sxSFUsername @client
    sxPayroll @client
  }
`

export const employeeDetailsQueryFromServer = gql`
  query ($empId: String) {
    employee(empId: $empId) {
      city
      citrixId
      country
      email
      firstName
      houseNumber
      jobTitle
      lastName
      legalEntityName
      personalMobile
      phone
      roomNumber
      state
      street
      workBuilding
      workMobile
      zipCode
      photoUrl
      dateFormat
      userLocale
      sxClient
      sxRegion
      costCenterName
      sixtbookEnabled
      costCenterCode
      publicProfile
      gdprAcceptance
      isbadgeConsentAccepted
      isLoginUserBadgeAdmin
      isManager
      isHR
      contractCountry
      isCadre
      externalEmail
      countryCode
      leaveRequestSetup
      isSysAdmin
      sfUserName
      isLeasingEmployee
      isSixtbookSocialFeedAdmin
      additionalTargetRegions
    }
    email @client
    name @client
    sxSFUsername @client
    sxPayroll @client
  }
`

export const getCalendarDetails = gql`
  query ($startDate: String, $endDate: String, $empId: String) {
    getCalendarDetails(
      startDate: $startDate
      endDate: $endDate
      empId: $empId
    ) {
      calendarList
      firstname
      lastname
      fullName
      previousYearsHolidays
      totalHolidays
      email
      citrixId
    }
  }
`
export const getDrupalContext = gql`
  query ($citrixId: String) {
    userQuery(
      filter: {
        conditions: [{operator: EQUAL, field: "name", value: [$citrixId]}]
      }
    ) {
      entities {
        ... on User {
          uid
        }
      }
    }
  }
`
export const getUserRoleByCitrixId = gql`
  query ($citrixId: String) {
    userQuery(
      filter: {
        conditions: [{operator: EQUAL, field: "name", value: [$citrixId]}]
      }
    ) {
      entities {
        ... on User {
          uid
          roles {
            targetId
          }
        }
      }
    }
  }
`
export const getEmailByCitrixId = gql`
  query ($citrixId: String) {
    userQuery(
      filter: {
        conditions: [{operator: EQUAL, field: "name", value: [$citrixId]}]
      }
    ) {
      entities {
        ... on User {
          mail
          fieldFirstName
          fieldLastName
        }
      }
    }
  }
`

export const getDeputy = gql`
  query ($empId: String) {
    getDeputy(empId: $empId) {
      id
      validTo
      validFrom
      user
      userCitrixId
      deactivateDeputy
      status
    }
  }
`

export const getEmployeeDetailsFromIAM = gql`
  query ($empId: String) {
    getEmployeeDetailsFromIAM(empId: $empId) {
      firstName
      lastName
      jobTitle
      costCenterCode
      costCenterName
      email
      legalEntityName
      streetAddress
      city
      country
      countryCode
      workMobile
      personalId
      sixtId
      externalEmail
      isWorkdayEnabled
      usageLocation
      isFormerEmployee
    }
  }
`
export const currentUserRolesQuery = gql`
  query {
    currentUserContext {
      roles {
        targetId
      }
    }
  }
`

export const saveDeputyUser = gql`
  mutation saveDeputyUser(
    $id: String
    $user: String
    $employee: String
    $deactivateDeputy: Boolean
    $validFrom: String
    $validTo: String
  ) {
    saveDeputyUser(
      id: $id
      user: $user
      employee: $employee
      deactivateDeputy: $deactivateDeputy
      validFrom: $validFrom
      validTo: $validTo
    ) {
      status
    }
  }
`
