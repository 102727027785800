export const ARTICLE_TYPES = {
  BUREAUCRACY_BUTTON: 'bureaucracy_button',
  BUREAUCRACY_BUTTON_OWN: 'bureaucracy_button_own',
  CORPORATE_NEWS: 'CORPORATE_NEWS',
  HR_COMMUNICATION: 'HR_COMMUNICATION',
  DIRECTLY_TO: 'directly_to',
  MOBILITY_NEWS: 'MOBILITY_NEWS',
  MY_FEEDS: 'MY_FEEDS',
  PRESS_RELEASED: 'PRESS_RELEASED',
  REPOST: 'REPOST',
  SOCIAL_NEWS: 'SOCIAL_NEWS',
  SOCIAL_POST: 'SOCIAL_POST',
  THINK_TANKS: 'THINK_TANKS',
  THINK_TANK_SEARCH: 'NodeThinkTank',
  DIRECTLY_TO_OWN: 'directly_to_own',
  DIRECTLY_TO_BOARD_MEMBER: 'directly_to_board_member',
  DIRECTLY_TO_BOARD_MEMBER_OWN: 'directly_to_board_member_own',
}

export const FILTERS = {
  [ARTICLE_TYPES.MY_FEEDS]: {
    conditions: [
      {field: 'type', value: 'news', operator: 'EQUAL'},
      {field: 'status', value: ['1!'], operator: 'EQUAL'},
      {field: 'sticky', value: 'false', operator: 'EQUAL'},
    ],
  },
  [ARTICLE_TYPES.PRESS_RELEASED]: {
    conditions: [
      {field: 'type', value: 'news', operator: 'EQUAL'},
      {field: 'status', value: ['1!'], operator: 'EQUAL'},
      {field: 'field_press_release', value: ['1'], operator: 'EQUAL'},
      {field: 'sticky', value: 'false', operator: 'EQUAL'},
    ],
  },
  [ARTICLE_TYPES.HR_COMMUNICATION]: {
    conditions: [
      {field: 'type', value: 'news', operator: 'EQUAL'},
      {field: 'status', value: ['1!'], operator: 'EQUAL'},
      {field: 'field_news_hr_communication', value: ['1'], operator: 'EQUAL'},
      {field: 'sticky', value: 'false', operator: 'EQUAL'},
    ],
  },
  [ARTICLE_TYPES.MOBILITY_NEWS]: {
    conditions: [
      {field: 'type', value: 'mobility_news', operator: 'EQUAL'},
      {field: 'status', value: ['1!'], operator: 'EQUAL'},
      {field: 'sticky', value: 'false', operator: 'EQUAL'},
    ],
  },
  [ARTICLE_TYPES.SOCIAL_NEWS]: {
    conditions: [
      {field: 'type', value: 'repost', operator: 'EQUAL'},
      {field: 'status', value: ['1!'], operator: 'EQUAL'},
    ],
  },
  [ARTICLE_TYPES.SOCIAL_POST]: {
    conditions: [
      {field: 'type', value: 'social_post', operator: 'EQUAL'},
      {field: 'status', value: ['1!'], operator: 'EQUAL'},
    ],
  },
}
export const NODE_MOBILITY_NEWS_TYPE = 'NodeMobilityNews'

export const SOCIAL_NEWS_TYPE = 'SocialPost'

export const NO_FOOTER_FEEDS = [
  'SOCIAL_NEWS',
  'MOBILITY_NEWS',
  'THINK_TANKS',
  'NodeThinkTank',
  'bureaucracy_button',
  'bureaucracy_button_own',
  'directly_to',
  'directly_to_own',
  'directly_to_board_member',
  'directly_to_board_member_own',
]
export const NO_LIKE_FETCH = [
  'SOCIAL_NEWS',
  'MOBILITY_NEWS',
  'THINK_TANKS',
  'NodeThinkTank',
  'bureaucracy_button',
  'bureaucracy_button_own',
  'directly_to',
  'directly_to_own',
  'directly_to_board_member',
  'directly_to_board_member_own',
]

export const ENTITY_BUNDLES_TYPES = {
  MOBILITY_NEWS: 'mobility_news',
  NEWS: 'news',
  SOCIAL_POST: 'social_post',
  REPOST: 'repost',
  WIKI: 'wiki',
  PAGE: 'page',
  THINK_TANK: 'think_tank',
  AIRLINE: 'airline',
  HOTEL: 'hotel',
}

export const READ_MORE_MAX_LENGTH = 201
export const READ_MORE_MAX_LENGTH_MOBILE = 136

export const ACTION_TYPES = {
  INCREASE_OFFSET: 'INCREASE_OFFSET',
  SET_FEEDS: 'SET_FEEDS',
  SET_LOADING: 'SET_LOADING',
  SET_REDIRECT: 'SET_REDIRECT',
  SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
  SET_SEARCH_SELECTED_FILTER: 'SET_SEARCH_SELECTED_FILTER',
  SET_SEARCH_SUGGESTIONS: 'SET_SEARCH_SUGGESTIONS',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  UPDATE_TAB: 'UPDATE_TAB',
  CLEAR_SELECTED_FILTER: 'CLEAR_SELECTED_FILTER',
  SET_INITIAL_LOADING: 'SET_INITIAL_LOADING',
  SET_WIKI_TAGS: 'SET_WIKI_TAGS',
  SET_MOB_SEARCH_SELECTED_FILTER: 'SET_MOB_SEARCH_SELECTED_FILTER',
  SET_SEARCH_HISTORY_SUGGESTIONS: 'SET_SEARCH_HISTORY_SUGGESTIONS',
  SET_COUNT: 'SET_COUNT',
  SET_CONTACT_OPTION: 'SET_CONTACT_OPTION',
  SET_NEWS_FEEDS: 'SET_NEWS_FEEDS',
  SET_SEARCH_FEEDS: 'SET_SEARCH_FEEDS',
  CLEAR_SEARCH_MODE: 'CLEAR_SEARCH_MODE',
}

export const HIDE_LIKE_COMMENT = [ARTICLE_TYPES.SOCIAL_NEWS]

export const ARTICLE_ACTION_TYPES = {
  ARTICLE_RECEIVED: 'ARTICLE_RECEIVED',
  ON_ADD_COMMENT: 'ON_ADD_COMMENT',
  UPDATE_COMMENTS: 'UPDATE_COMMENTS',
  SET_LIKE_UNLIKE: 'SET_LIKE_UNLIKE',
  SET_TABLE_SORT_UPDATED: 'SET_TABLE_SORT_UPDATED',
  UPDATE_LIKES: 'UPDATE_LIKES',
}

export const AIRLINE_CONSTANTS = {
  airlineFieldNames: [
    'fieldAirlinePartner',
    'fieldAirlineNews',
    'fieldAirlineStatusLevel',
    'fieldAirlineMiles',
    'fieldAirlineLevelOfIntegrati',
    'fieldAirlineEmployeeBenefits',
    'fieldAirlineBookingMicrosite',
    'fieldAirlinePromotions',
    'fieldAirlineAccountManagerAt',
    'fieldAirlineRedemption',
    'fieldAirlineWhisperLine',
  ],
  hotelFieldNames: [
    'fieldHotelPartner',
    'fieldHotelNews',
    'fieldHotelStatusLevel',
    'fieldHotelPoints',
    'fieldHotelLevelOfIntegration',
    'fieldHotelEmployeeBenefits',
    'fieldHotelBookingMicrosite',
    'fieldHotelWhisperLine',
    'fieldHotelRedemption',
    'fieldHotelPromotions',
    'fieldHotelAccountManagerAtS',
  ],
}
