import gql from 'graphql-tag.macro'
export const getSearchResultsQuery = gql`
  query (
    $searchTerm: String
    $filter: JSON
    $size: String
    $searchType: String
    $offset: Int
    $empId: String
    $sort: String
  ) {
    searchResults(
      searchTerm: $searchTerm
      filter: $filter
      size: $size
      searchType: $searchType
      offset: $offset
      empId: $empId
      sort: $sort
    ) {
      results
      type
    }
  }
`
export const getSearchHistoryQuery = gql`
  query ($empId: String, $searchType: String) {
    getMySearchHistory(empId: $empId, searchType: $searchType) {
      searchTerms
    }
  }
`
export const saveSearchHistory = gql`
  mutation ($empId: String, $searchTerm: String, $searchType: String) {
    saveMySearch(
      empId: $empId
      searchTerm: $searchTerm
      searchType: $searchType
    ) {
      status
      errorMessage
    }
  }
`
export const recentelyUpdatedWiki = gql`
  {
    nodeQuery(
      offset: 0
      limit: 5
      sort: [{field: "changed", direction: DESC}]
      filter: {
        conditions: [
          {field: "type", value: "wiki", operator: EQUAL}
          {field: "status", value: ["1!"], operator: EQUAL}
        ]
      }
    ) {
      entities {
        entityBundle
        ... on NodeWiki {
          title
          nid
          created
          changed
        }
      }
    }
  }
`
export const newSixtians = gql`
  {
    getNewSixtians {
      results
      type
    }
  }
`
