export const TYPENAME = '__typename'
export const ERROR_MESSAGE = `Error occured. Please try again or open an incident in the <a href="https://sixtprod.service-now.com/pac?id=pac" target="blank"> People Support Portal </a>`
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const SUCCESS_MESSAGE = 'Changes saved successfully'
export const applicableDateFormats = 'DMYdy'
export const defaultDateFormat = 'DD.MM.YYYY'
export const salesforceDateFormat = 'YYYY-MM-DD'
export const defaultUserLocale = 'de-DE'
export const intervalTimeBeforeExpiry = 3300000
export const languageCodeToName: {[index: string]: any} = {
  en: 'English',
  fr: 'France',
  us: 'USA',
  at: 'Austria',
  ch: 'Switzerland',
  de: 'Germany',
  it: 'Italy',
  uk: 'UK',
  es: 'Spain',
  benelux: 'Benelux',
  franchise: 'Franchise',
  in: 'India',
  pt: 'Portugal',
  ca: 'Canada',
}
export const reverseEntityCommentLimit = 2147483647
export const drupalFormBaseUrl =
  process.env.REACT_APP_ENV_TYPE === 'production'
    ? 'https://sixtbook-drupal-forms.sfdc.sixt.com'
    : 'https://sixtbook-drupal-forms.dev.sfdc.sixt.com'
export const franchiseUserEmailAddressFormat = '@franchise.sixt.com'
export const franchiseEditProfileUrl =
  'https://sixtprod.service-now.com/sp?id=sc_cat_item&sys_id=50ecb327db1c1c503a97264cd39619de'
export const REACTION_TYPES = {
  LIKE: 'like',
  LOVE: 'love',
  APPLAUD: 'angry',
  LAUGH: 'laugh',
  WOW: 'wow',
  SAD: 'sad',
}
