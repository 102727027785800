/**
 * SideBarPanel - Collapsible Content
 * Displays list of App with collapsible Sub Menu
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'

import {getAnchorProperties} from './utils.SideBarPanel'
import {ImageCollections} from '../../utils/imageImports'
import {checkObjProps, isMobile} from '../../utils/genericUtils'
import {NavLink} from 'react-router-dom'
import {getContext} from 'recompose'
import classNames from 'classnames'
import {IProps, IData} from './SideBarPanel.d'
import SubMenu from './SubMenu'
import './sideBarPanel.scss'
import {compose, graphql} from 'react-apollo'
import {employeeDetailsQuery} from '../../queries/employeeQuery'
import LoginAsInfo from './LoginAsInfo'
import {sixtFbBatch} from '../../queries/sixtFbNewsIdsQuery'
import {targetPropTypes} from '../../commonPropsType'

const SideBarPanel = (props: IProps) => {
  const {
    expand = false,
    onCollapse,
    citrixId = '',
    isSixtbookSocialFeedAdmin,
  } = props
  const onClose = () => {
    onCollapse(false)
  }
  const anchorProperties = getAnchorProperties(
    citrixId,
    isSixtbookSocialFeedAdmin
  )
  const batchTrigger = async () => {
    await props.sixtFbBatchQuery({
      variables: {
        targetId: props.regionIdentifier,
      },
    })
  }
  const releaseNotesHoverText =
    'This link is redirecting to Confluence. You need to have Confluence permission to be able to see the Release Notes.'
  return (
    <div className={classNames('sidebar-collapsible', {expanded: expand})}>
      <ul>
        <li className="main-home" onClick={onClose}>
          <img src={ImageCollections.ico_house} alt="Home" />
          <NavLink to="/">Home</NavLink>
        </li>
        {anchorProperties.map((list: IData, index) => (
          <React.Fragment key={index}>
            {list.subMenu && <SubMenu list={list} onCollapse={onClose} />}
            {!list.subMenu && (
              <li
                key={index}
                onClick={
                  list.name === 'Sixt Social Admin' ? batchTrigger : onClose
                }
              >
                {list.iconName && (
                  <img src={ImageCollections[list.iconName]} alt={list.name} />
                )}
                {list.to &&
                  (list.targetBlank ? (
                    <a
                      target="_blank"
                      href={list.to}
                      rel="noopener noreferrer"
                      title={
                        list.name === 'Release Notes'
                          ? releaseNotesHoverText
                          : ''
                      }
                    >
                      {list.name}
                    </a>
                  ) : (
                    <NavLink to={list.to}>{list.name}</NavLink>
                  ))}
              </li>
            )}
          </React.Fragment>
        ))}
        {isMobile() && (
          <li>
            <NavLink activeClassName="active" to="/logout">
              Logout
            </NavLink>
          </li>
        )}
        <li className="login-as-li">
          <LoginAsInfo />
        </li>
      </ul>
    </div>
  )
}
export default compose(
  getContext(targetPropTypes),
  graphql(employeeDetailsQuery, {
    props: ({data}: any) => {
      return {
        citrixId: checkObjProps(data, ['employee', 'citrixId'], ''),
        isSixtbookSocialFeedAdmin: checkObjProps(
          data,
          ['employee', 'isSixtbookSocialFeedAdmin'],
          false
        ),
      }
    },
  }),
  graphql(sixtFbBatch, {
    name: 'sixtFbBatchQuery',
  })
)(SideBarPanel)
