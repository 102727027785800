import {getCookie} from './genericUtils'
import {ILoginAsFields} from '../commonType.d'
import {cookieDomain} from '../constants/graphqlConstants'

/**
 * Check if Cookie Consent is already accepted by the user
 */
export const checkConsentCookie = () => {
  const consentAccespted = window.getCookieByName('sixtbook-tracking-consent')
  if (consentAccespted && consentAccespted !== '') {
    return true
  } else {
    return false
  }
}

/**
 * Set a cookie in the browser
 * @param cName Cookie Name
 * @param cVal Value to be stored in cookie
 */
export const setCookie = (
  cName: string,
  cVal: string,
  expiaratinInDays = 90
) => {
  const d = new Date()
  d.setTime(d.getTime() + expiaratinInDays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  const domain = `domain=${cookieDomain}`
  document.cookie = cName + '=' + cVal + ';' + expires + ';path=/;' + domain
}

export const getLoginAsInfoFromCookie = (): ILoginAsFields => {
  const initLoginProfile = {
    email: '',
    sfUserName: '',
    firstName: '',
    lastName: '',
    citrixId: '',
  }
  if (process.env.REACT_APP_ENV_TYPE !== 'production') {
    try {
      const loginAsInformation = getCookie('loginAs')
      if (loginAsInformation) {
        return JSON.parse(loginAsInformation)
      }
    } catch (ex) {
      return initLoginProfile
    }
  }
  return initLoginProfile
}

export const deleteCookieByName = (cName: string) => {
  document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookieDomain}`
}

export const checkMWCookieConsent = empId => {
  const consentAccepted =
    window
      .getCookieByName('sixtbook-mw-consent')
      .indexOf(`${empId}--mwaccepted`) > -1
  return consentAccepted
}

export const checkElearningBTConsent = empId => {
  const consentAccepted =
    window
      .getCookieByName('sixtbook-bt-consent')
      .indexOf(`${empId}--btAccepted`) > -1
  return consentAccepted
}
