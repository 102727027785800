import * as React from 'react'
import moment from 'moment'
import {render} from 'react-dom'
import App from './App'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import {createClient} from './graphql/config'
import {loginOnSessionTimeout} from './utils/auth'
import {getCookie} from './utils/genericUtils'

const initializeApp = async () => {
  const userInfo = getCookie('loginUserInfo')
  let isExpired = true
  let expiry = 0
  if (userInfo && decodeURIComponent(userInfo)) {
    const exp = JSON.parse(decodeURIComponent(userInfo)).exp
    expiry = Number(exp)
    isExpired = moment(expiry * 1000).isSameOrBefore(moment())
  }
  if (isExpired) {
    loginOnSessionTimeout()
  } else {
    const client = await createClient()
    // periodicallyRefreshToken(expiry)
    render(<App client={client} />, document.getElementById('root'))
  }
}
if (window.location.pathname.indexOf('get-social-code') > -1) {
  window.opener.postMessage(window.location.search, window.location.origin)
  window.close()
  render(<div>Hi, Get the code</div>, document.getElementById('root'))
} else {
  initializeApp()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
