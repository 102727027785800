/**
 * Header - Header of App
 * Display Sixt Logo,
 * Placement of Search area,
 * SideBar Collapsible Icons,
 * User Image,
 * SideBarPanel Component inclusive
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import {ClickAwayListener, Grid, IconButton} from '@material-ui/core'
import {ImageCollections} from '../../utils/imageImports'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import UserPopper from './UserPopper'
import Notification from './Notification'
import SideBarPanel from './SideBarPanel'
import SearchBar from './SearchBar'

import './header.scss'
import LoginAsInfo from './LoginAsInfo'
import {isMobile} from '../../utils/genericUtils'

class Header extends React.Component {
  public state = {
    menuCollapsed: false,
    mobileSearch: false,
    panelCollapsed: false,
    displayOldLogo: false,
  }
  public panelCollapsible = (hide?: boolean) => {
    const {panelCollapsed} = this.state
    this.setState({
      panelCollapsed: hide ? false : !panelCollapsed,
    })
  }
  public toggleSearchForMobile = () => {
    const mobileCheck = localStorage.getItem('mobileUser')
    const isMobile =
      !this.state.mobileSearch && !!mobileCheck && mobileCheck === 'true'
    this.setState({mobileSearch: isMobile})
  }
  public handleLogoClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (e.shiftKey && e.type === 'click') {
      e.preventDefault()
      this.setState({
        displayOldLogo: !this.state.displayOldLogo,
      })
    }
  }
  public render() {
    const searchIcoStyle = this.state.mobileSearch
      ? 'search-len search-cancel button-with-transparent'
      : 'search-len button-with-transparent'
    return (
      <React.Fragment>
        <div className="header">
          <Grid
            xs={12}
            container={true}
            item={true}
            className="header-container"
            alignItems="center"
          >
            {!this.state.mobileSearch && (
              <>
                <ClickAwayListener
                  onClickAway={() => this.panelCollapsible(true)}
                >
                  <div>
                    <IconButton
                      aria-label="Open drawer"
                      onClick={() => this.panelCollapsible()}
                    >
                      <FontAwesomeIcon
                        icon={faBars}
                        className="menu-icon"
                        color="white"
                      />
                    </IconButton>
                    <SideBarPanel
                      expand={this.state.panelCollapsed}
                      onCollapse={this.panelCollapsible}
                    />
                  </div>
                </ClickAwayListener>
                <NavLink
                  to="/"
                  className={`logo-container ${
                    this.state.displayOldLogo ? 'old-logo' : ''
                  }`}
                  onClick={e => this.handleLogoClick(e)}
                />
              </>
            )}
            {this.state.mobileSearch && (
              <div className="search-container-mobile">
                <SearchBar />
              </div>
            )}
            <div className="search-container">
              <Grid
                item={true}
                className="select-text"
                container={true}
                alignItems="center"
              >
                <img
                  src={ImageCollections.ico_magnifier}
                  alt="search magnifier"
                />
              </Grid>
              <SearchBar />
            </div>
            <Grid
              xs={12}
              sm={true}
              container={true}
              item={true}
              className="user-settings"
              alignItems="center"
              justify="flex-end"
            >
              <LoginAsInfo />
              {!isMobile() && <Notification />}
              <UserPopper />
            </Grid>
            <button
              className={searchIcoStyle}
              onClick={this.toggleSearchForMobile}
              data-testid="toggle-mobile"
            />
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

export default Header
