import {urls} from '../constants/graphqlConstants'
import moment from 'moment'
import {intervalTimeBeforeExpiry} from '../constants/commonConstants'
// import {parseJwt} from './parseJWT'

export const loginOnSessionTimeout = async () => {
  redirectToLogin()
}

export const logoutClearTokens = () => {
  redirectToLogOut()
}

export const redirectToLogin = () => {
  const url = urls[process.env.REACT_APP_ENV_TYPE || 'development']
  localStorage.setItem('kc_url', window.location.href)
  window.location.href = `${url}/login?url=${
    window.location.href
  }&redirectHostName=${url}`
}

export const redirectToLogOut = () => {
  window.location.href = `${urls[process.env.REACT_APP_ENV_TYPE || 'development']}/logout`
}

export const refreshToken = async () => {
  const url = urls[process.env.REACT_APP_ENV_TYPE || 'development']
  const reqParams = {
    clientURL: localStorage.getItem('kc_url'),
    redirectHostName: url,
  }
  try {
    const response = await fetch(url + '/refreshToken', {
      body: JSON.stringify(reqParams),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
    })

    if (response) {
      const tokenResponse = await response.json()
      if (tokenResponse && tokenResponse.idToken) {
        // const parsedToken = parseJwt(tokenResponse.idToken)
        // periodicallyRefreshToken(parsedToken.exp)
      } else {
        loginOnSessionTimeout()
      }
    }
  } catch (error) {
    loginOnSessionTimeout()
  }

  return null
}

let tokenUpdateInterval: any = null

export const periodicallyRefreshToken = (exp: number) => {
  const milliSec = exp
    ? moment(exp * 1000).diff(moment(), 'milliseconds') - 300000 // Minus with 5 min
    : intervalTimeBeforeExpiry
  if (tokenUpdateInterval) {
    clearInterval(tokenUpdateInterval)
  }
  tokenUpdateInterval = setInterval(
    () => {
      refreshToken()
    },
    milliSec > 0 ? milliSec : 1
  )
}
