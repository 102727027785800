/**
 * SXTCountBadge - to display count in form of badge
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickShanmugam0689)
 */
import * as React from 'react'
import {IProps} from './SXTCountBadge.d'
import './SXTCountBadge.scss'

const SXTCountBadge = (props: IProps) => {
  const {cssClass, count} = props
  return <span className={`sxt-count-badge ${cssClass}`}>{count}</span>
}

export default SXTCountBadge
