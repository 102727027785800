import * as React from 'react'
import {graphql} from 'react-apollo'
import {employeeDetailsQuery} from '../queries/employeeQuery'
import {checkObjProps} from './genericUtils'

const graphqlDynamicQueryGenerator = (query: any, config: any) => {
  return (component: any) => {
    return React.memo((props: any) => {
      return React.createElement(
        graphql(query(props), config)(component),
        props
      )
    })
  }
}

const writeToCache = (employee: any) => {
  sessionStorage.setItem('dateFormat', employee.dateFormat || 'DD.MM.YYYY')
  sessionStorage.setItem('userLocale', employee.userLocale || 'de-DE')
}

/**
 * returns a parameter value of an Object.
 * use this when you need to get variables Object for Profile Tab Component, return value of an object or null.
 * ex -: if we want to check conditions ({Apollo<Client>,Router<match>}) and return Object,
 * we can use getProfileVariables({Apollo<Client>,Router<match>});
 *
 * @param {Object,Object}   obj    object to be parsed
 * @returns {variables:{empId:'somevalue' || null}}  value of the object property
 */
const getProfileVariables = ({client, match}: any) => {
  return {
    variables: {
      empId: getUserCitrixId(client, match),
    },
  }
}
/**
 * returns a parameter value of an String.
 * use this when you need to get citrixId, return value of an string or null.
 * ex -: if we want to check conditions (Apollo<Client>,Router<match>) and return Object,
 * we can use getUserCitrixId(Apollo<Client>,Router<match>);
 *
 * @param1 Object   obj    object to be parsed
 * @param2 Object   obj    object to be parsed
 * @returns "g9968"  value of the object property
 */
const getUserCitrixId = (client: any, empId: any) => {
  try {
    const {
      employee: {
        citrixId,
        gdprAcceptance,
        publicProfile,
        email,
        isManager,
        isHR,
        contractCountry,
        isCadre,
        isbadgeConsentAccepted,
        isLoginUserBadgeAdmin,
        leaveRequestSetup,
        isSysAdmin,
      },
    } = client.readQuery({
      query: employeeDetailsQuery,
      variables: {
        empId: null,
      },
    })
    const otherProfileEmpId = empId
    const isOtherUser = !!otherProfileEmpId && otherProfileEmpId !== citrixId

    return {
      empId: isOtherUser ? otherProfileEmpId : citrixId,
      email,
      gdprAcceptance,
      isOtherUser,
      publicProfile,
      isManager,
      isHR,
      contractCountry,
      isCadre,
      isbadgeConsentAccepted,
      isLoginUserBadgeAdmin,
      leaveRequestSetup,
      isSysAdmin,
    }
  } catch (err) {
    console.log(err)
  }
  return {
    empId: null,
    gdprAcceptance: false,
    isOtherUser: false,
    publicProfile: false,
  }
}

const getCitrixId = (client: any) => {
  try {
    const {
      employee: {citrixId},
    } = client.readQuery({
      query: employeeDetailsQuery,
      variables: {
        empId: null,
      },
    })
    return {citrixId}
  } catch (err) {
    console.log(err)
  }
  return {citrixId: ''}
}
/**
 *
 * @param data graphql data for getSchemaPickListValuesBasedOnCountry
 * @returns array of picklist option
 */
const pickListMappingFn = (data: any) => {
  return checkObjProps(
    data,
    ['getSchemaPickListValuesBasedOnCountry', 'picklistOptions'],
    []
  )
}

export {
  getCitrixId,
  getProfileVariables,
  getUserCitrixId,
  graphqlDynamicQueryGenerator,
  writeToCache,
  pickListMappingFn,
}
