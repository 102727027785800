import ApolloClient from 'apollo-client'
import {
  employeeDetailsQuery,
  employeeDetailsQueryFromServer,
} from '../queries/employeeQuery'
import {checkObjProps} from './genericUtils'
import {getLoginAsInfoFromCookie, deleteCookieByName} from './cookieUtils'

export const getUserProfileFromCitrixId = async (
  citrixId: string,
  client: ApolloClient<any>
) => {
  try {
    let userResponse
    let arrayObj = ['employee']
    try {
      userResponse = await client.readQuery({
        query: employeeDetailsQuery,
        variables: {empId: citrixId},
      })
    } catch (err) {
      userResponse = null
    }
    if (!userResponse) {
      userResponse = await client.query({
        fetchPolicy: 'network-only',
        query: employeeDetailsQueryFromServer,
        variables: {empId: citrixId},
      })
      arrayObj = ['data', 'employee']
    }

    if (userResponse) {
      const employeeInfo = checkObjProps(userResponse, arrayObj, {})
      return {
        employeeId: employeeInfo.citrixId,
        employeeEmail: employeeInfo.email,
        employeeName: employeeInfo.firstName + ' ' + employeeInfo.lastName,
      }
    }
  } catch (ex) {
    return null
  }
}

export const logoutFromLoginAs = () => {
  const loginAsUserInfo = getLoginAsInfoFromCookie()
  if (loginAsUserInfo.citrixId) {
    deleteCookieByName('loginAs')
    deleteCookieByName('sfid_dev_loginas')
    window.location.href = `/view-employee/${loginAsUserInfo.citrixId}`
  }
}
