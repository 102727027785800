/**
 * SXTLiveNotificationBell - to display bell with live notifcation support
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickShanmugam0689)
 */
import * as React from 'react'
import SXTNotificationBell from './SXTNotificationBell'

const SXTLiveNotificationBell = () => {
  const [isWindowActive, setIsWindowActive] = React.useState(true)

  // Seting up the visibility event listener to track whether the window is active or not
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsWindowActive(true)
      } else {
        setIsWindowActive(false)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return <SXTNotificationBell isWindowActive={isWindowActive} />
}

export default React.memo(SXTLiveNotificationBell)
