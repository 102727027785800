import moment from 'moment-timezone'

import {
  applicableDateFormats,
  defaultDateFormat,
} from '../constants/commonConstants'

/**
 * compare dates and returns whether date1 is greater than date2
 *
 * @param {String or Date}   date1    date
 * @param {String or Date}   date2    date
 * @returns {boolean}  date1 > date2
 */
export const compareDates = (date1: any, date2: any) => {
  // Hardcoded to YYYY-MM-DD as of now. But will change to user locale date format
  const dateFormat = 'YYYY-MM-DD'
  if (date1 instanceof Date) {
    date1 = moment(date1).format(dateFormat)
  }
  if (date2 instanceof Date) {
    date2 = moment(date2).format(dateFormat)
  }
  return moment(date1, dateFormat, true) > moment(date2, dateFormat, true)
}

/**
 * gets the modified date format for MUI Pickers
 * e.g. DD.MM.YYYY will be used as dd.MM.yyyy with MUI Pickers
 *
 * @param {String}   dateFormat    format to be used
 * @returns {String}  format returned to be used with MUI Pickets
 */
export const getModifiedDateFormatForMUI = (dateFormat = defaultDateFormat) => {
  return dateFormat.replace(/[D]/g, 'd').replace(/[Y]/g, 'y')
}

/**
 * gets the masking data for MUI Pickers to be used for keyboard input
 * e.g. for DD.MM.YYYY the masking is [/\d/ , /\d/ , ., /\d/ ,/\d/, ., /\d/, /\d/, /\d/, /\d/]
 *
 * @param {String}   dateFormat    format to be used
 * @returns {String[]}  masking array to be used with MUI Pickers
 */
export const getMaskForKeyboardInput = (dataFormat = defaultDateFormat) => {
  const maskForMUI = []
  for (const eachChar of dataFormat) {
    if (applicableDateFormats.includes(eachChar)) {
      maskForMUI.push(/\d/)
    } else {
      maskForMUI.push('.')
    }
  }
  return maskForMUI
}

/**
 * get the default date format for the user loggedin
 *
 * @returns {String}  dateformat taken from Session storage or default one
 */
export const getDateFormat = () => {
  return sessionStorage.getItem('dateFormat') || defaultDateFormat
}

/**
 * get the difference between two dates in years
 *
 * @param {String}   date1  first date
 * @param {String}   date2  second date
 * @param {String}   dateFormat  dateFormat as string
 * @returns {String}  difference between date1 and date2 in years
 */
export const getDifferenceInDateInYears = (
  date1: string,
  date2: string,
  dateFormat: string
) => {
  dateFormat = dateFormat || getDateFormat()
  const momentDate1 = (date1 && moment(date1, dateFormat, true)) || moment()
  const momentDate2 = (date2 && moment(date2, dateFormat, true)) || moment()
  const differenceInDates = moment.duration(momentDate2.diff(momentDate1))
  return differenceInDates.asYears()
}

/**
 * get the difference between multiple dates as years
 *
 * @param {Array[] Object}     objList List of objects
 * @param {String}   validFromAPI  firstDateParam
 * @param {String}   validToAPI  secondDateParam
 * @param {String}   dateFormat  dateFormat
 * @returns {Integer} difference from the ranges
 */
export const getRangesFromOverlappingDates = (
  objList: any[],
  validFromAPI: string,
  validToAPI: string,
  dateFormat: string
) => {
  const monthYearSet = new Set()
  objList.forEach((obj: any) => {
    const startMoment =
      (obj[validFromAPI] && moment(obj[validFromAPI], dateFormat, true)) ||
      moment()
    const endMoment =
      (obj[validToAPI] && moment(obj[validToAPI], dateFormat, true)) || moment()
    const interimMoment = startMoment.clone()
    while (
      endMoment > interimMoment ||
      interimMoment.format('M') === endMoment.format('M')
    ) {
      monthYearSet.add(interimMoment.format('YYYY-MM'))
      interimMoment.add(1, 'month')
    }
  })
  return monthYearSet.size / 12
}

/**
 * get the year and month from passed in numerical value
 *
 * @param {number}   numericalValue  number to be parsed to year and month
 * @returns {String}  response in '{yearValue} Year(s) and ${monthValue} month(s)'
 */
export const getYearMonthFromNumber = (numericalValue: number) => {
  const yearValue = Math.floor(numericalValue)
  let monthValue = numericalValue - Math.floor(numericalValue)
  monthValue = Math.round(monthValue * 12)
  let yearMonth = ''
  if (yearValue) {
    yearMonth = `${yearValue} Year(s)`
    if (monthValue) {
      yearMonth += ` and ${monthValue} month(s)`
    }
  } else if (monthValue) {
    yearMonth = `${monthValue} month(s)`
  } else {
    yearMonth = 'Less than 1 month'
  }
  return yearMonth
}

/**
 * get the list of days in a week
 */
export const getWeekDays = () => {
  const startOfWeek = moment().startOf('isoWeek')
  const endOfWeek = moment(startOfWeek).add(4, 'days')

  const days = []
  let day = startOfWeek

  while (day <= endOfWeek) {
    days.push(day.toDate())
    day = day.clone().add(1, 'd')
  }

  return days
}

export const convertUnixEpoc = (epocTime: any) => {
  return moment.unix(epocTime).format('DD.MM.YYYY HH:mm')
}

export const isVotingApplicable = (selectedDate: any) => {
  const todayDate = moment()
  const today1pmDate = moment.tz(
    moment().hours(13).minutes(0).format('YYYY-MM-DD HH:mm'),
    'Europe/Berlin'
  )
  const yesterdayDate = moment(todayDate).add(-1, 'days')
  const menuDate = moment(selectedDate, getDateFormat(), true)
  const isValid =
    (menuDate.isSame(yesterdayDate, 'day') && todayDate < today1pmDate) ||
    (menuDate.isSame(todayDate, 'day') && todayDate >= today1pmDate)
  return isValid
}

export const compareTwoDates = (date1: string, date2: string) => {
  return (
    (date1 &&
      date2 &&
      moment(date1).hours(0).minutes(0).seconds(0).format() >
        moment(date2).hours(0).minutes(0).seconds(0).format()) ||
    false
  )
}

export const formatDateTimeFromString = (dateString: string = '') => {
  if (!dateString) {
    return ''
  }
  const dateFormat = getDateFormat()
  const newDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss').format(
    'ddd ' + dateFormat + ', HH:mm'
  )
  return newDate
}
