import {defaultUserLocale} from '../constants/commonConstants'

/**
 * get the default user locale for the user loggedin
 *
 * @returns {String}  userLocale taken from Session storage or default one
 */
export const getUserLocale = () => {
  return sessionStorage.getItem('userLocale') || defaultUserLocale
}

/**
 * Function for returning international number format with currency
 * ex -: if we want to bind international number format with currency ("12445","EUR") and return "12.445,00 €",
 *
 * @param {val}   string
 * @param {currency}   string
 * @returns {string}  return string value of the formatted number with currency
 *
 */
export const intlNumbFormatCurrency = (val: any, currency = 'EUR') => {
  if (!val) {
    return ''
  }
  const NumberFormat = new Intl.NumberFormat(getUserLocale(), {
    currency,
    style: 'currency',
  })
  return NumberFormat.format(val)
}
/**
 * Function for returning international number format
 * ex -: if we want to bind international number format with currency ("12445") and return "12.445,00",
 *
 * @param {val}   string
 * @returns {string}  return string value of the formatted number with currency
 *
 */
export const intlNumbFormat = (val: any, defaultDecimalDigits = 2) => {
  if (val === 0) {
    return 0
  }
  if (!val) {
    return ''
  }
  const NumberFormat = new Intl.NumberFormat(getUserLocale(), {
    maximumFractionDigits: defaultDecimalDigits,
    minimumFractionDigits: defaultDecimalDigits,
  })
  return NumberFormat.format(val)
}
