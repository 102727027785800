export const searchTitles = {
  contacts: 'Contacts',
  news: 'News',
  wiki: 'Wiki',
  Countries: 'Countries',
  Departments: 'Departments',
  Employees: 'Employees',
  deepLinks: 'Direct Links',
  socialnews: 'Social News',
}

export const searchFilters = {
  news: {
    postedBy: 'authored by',
    range: 'date posted',
    categories: 'news category',
    targetGroups: 'locations',
  },
  people: {
    locations: 'locations',
    department: 'departments',
  },
  wiki: {
    postedBy: 'authored by',
    range: 'date posted',
    topics: 'categories',
    wikiType: 'Wiki Type',
  },
  socialnews: {
    postedBy: 'authored by',
    range: 'date posted',
  },
}
