/**
 * Tags Component which contains Tag of label
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import './tags.scss'

interface IProps {
  data?: Array<{entity: {entityLabel: string}}>
  nid?: string
  tags?: string[]
}

const Tags = ({data = [], nid, tags}: IProps) => {
  const [tagsToShow, setTagLimit] = React.useState(10)
  return (
    <div className="tag-chip-container">
      {data.slice(0, tagsToShow).map((datum: any, i: number) =>
        datum.entity ? (
          <NavLink
            to={`/wiki/article/${nid}/${datum.targetId}`}
            className="tag-chip-name"
            key={datum.targetId}
          >
            <span>{datum.entity.entityLabel}</span>
          </NavLink>
        ) : null
      )}
      {data && data.length >= 10 && tagsToShow !== data.length && (
        <button
          data-testid="show-more-tag-chip"
          className="show-more-tag-chip button-with-transparent"
          onClick={() => setTagLimit(data.length)}
        >
          {`+${data.length - 10} More`}
        </button>
      )}
      {tags &&
        tags.length &&
        tags.map((tag: string, index: number) => (
          <div className="tag-chip-name" key={index}>
            {tag}
          </div>
        ))}
    </div>
  )
}

export default Tags
