/**
 * UserPopper - Display Profile Avatar with
 * Dropdown options ( My Profile, Bookmarks, Help,
 * Logout )
 * and redirecting router link
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'
import {NavLink, withRouter} from 'react-router-dom'
import classNames from 'classnames'
import {
  Button,
  Divider,
  Fade,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ADFSAvatar from '../common/ADFSAvatar/ADFSAvatar'
import './userPopper.scss'

class UserPopper extends React.Component<any, any> {
  public state = {showPopper: false}
  public anchorEl: any
  public handleToggle = (showPopper: boolean) => {
    this.setState({showPopper})
  }

  public componentDidUpdate = (prevProps: any) => {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.state.showPopper
    ) {
      this.handleToggle(false)
    }
  }

  public render() {
    const {showPopper} = this.state
    return (
      <div className="user-avatar">
        <Button
          buttonRef={node => {
            this.anchorEl = node
          }}
          className={classNames('user-btn', {active: showPopper})}
          aria-haspopup="true"
          data-testid="user-btn"
          onClick={() => this.handleToggle(true)}
        >
          <ADFSAvatar className="profile_pic avatar" />
        </Button>
        <Popper
          open={showPopper}
          anchorEl={this.anchorEl}
          transition={true}
          className="user-popper"
        >
          {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="user-paper">
                <ClickAwayListener onClickAway={() => this.handleToggle(false)}>
                  <MenuList>
                    <MenuItem>
                      <NavLink activeClassName="active" to="/profile">
                        My Profile
                      </NavLink>
                    </MenuItem>
                    {/* <MenuItem>
                      <a
                        href="https://sixtbook.sixt.com/my-bookmarks"
                        target="_blank"
                      >
                        Bookmarks
                      </a>
                    </MenuItem> */}
                    {/* <MenuItem onClick={() => this.handleToggle(false)}>
                      <a
                        href="https://sixtbook.sixt.com/content/sixtbook-help-0"
                        target="_blank"
                      >
                        Help
                      </a>
                    </MenuItem> */}
                    <Divider />
                    <MenuItem>
                      <NavLink activeClassName="active" to="/logout">
                        Logout
                      </NavLink>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    )
  }
}

export default withRouter(UserPopper)
