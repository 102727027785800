import gql from 'graphql-tag.macro'

export const getUserProfileInfo = gql`
  query ($emailIdList: [String]) {
    getBulkUserProfileInfo(emailIdList: $emailIdList) {
      citrixId
      companyName
      department
      emailId
      jobTitle
      name
      profilePhotoURL
      sxHolding
    }
    error
  }
`
export const getUserProfileInfoFromClient = gql`
  query ($emailIdList: [String]) {
    getBulkUserProfileInfo(emailIdList: $emailIdList) @client {
      citrixId
      companyName
      department
      emailId
      jobTitle
      name
      profilePhotoURL
      sxHolding
    }
    error
  }
`
export const getUserPhoneNumber = gql`
  query ($emailIdList: [String], $shouldGetUserProfile: Boolean) {
    getBulkUserProfileInfo(
      emailIdList: $emailIdList
      shouldGetUserProfile: $shouldGetUserProfile
    ) {
      businessPhones
    }
    error
  }
`
export const getUserSxPayroll = gql`
  query ($emailIdList: [String], $shouldGetUserProfile: Boolean) {
    getBulkUserProfileInfo(
      emailIdList: $emailIdList
      shouldGetUserProfile: $shouldGetUserProfile
    ) {
      sxPayroll
    }
  }
`
