/**
 * SXTEmptyData Component - Empty notification information and image
 *
 * @version 1.0.6
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'
import {ImageCollections} from '../../../utils/imageImports'
import './sxtEmptyData.scss'
const SXTEmptyData = () => (
  <div className="sxt-empty-data">
    <p>
      <img
        className="icon-bell"
        src={ImageCollections.ico_ring_bell}
        alt="Notification icon"
      />
    </p>
    <h2>NOTHING THAT RINGS A BELL!</h2>
  </div>
)

export default SXTEmptyData
