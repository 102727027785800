const isInLocalHost = window.location.hostname === 'localhost'

export const urls: {[index: string]: any} = {
  development: isInLocalHost
    ? 'http://localhost:4001'
    : 'https://sixtbook-backend.dev.sfdc.sixt.com',
  production: 'https://api.sixtbook.sixt.com',
  staging: 'https://api.staging-sixtbook.sixt.com',
}

export const backendURLs: {[index: string]: any} = {
  development: 'https://sixtbook-backend.dev.sfdc.sixt.com',
  production: 'https://sixtbook-backend.sfdc.sixt.com',
  staging: 'https://sixtbook-backend.dev.sfdc.sixt.com',
}

export const sixtbookUrl: {[index: string]: any} = {
  development: isInLocalHost
    ? 'localhost:4001'
    : 'sixtbook-backend.dev.sfdc.sixt.com',
  production: 'api.sixtbook.sixt.com',
  staging: 'api.staging-sixtbook.sixt.com',
}

export const backendHost: {[index: string]: any} = {
  development: 'sixtbook-backend.dev.sfdc.sixt.com',
  production: 'sixtbook-backend.sfdc.sixt.com',
  staging: 'sixtbook-backend.dev.sfdc.sixt.com',
}

export const graphqlConstants: {[index: string]: any} = {
  endpoint: `${urls[process.env.REACT_APP_ENV_TYPE || 'development']}/graphql`,
  wsEndpoint: `${urls[process.env.REACT_APP_ENV_TYPE || 'development'].replace(
    'http',
    'ws'
  )}/graphql`,
}

const cookieConstants: {[index: string]: any} = {
  production: 'sixtbook.sixt.com',
  staging: 'staging-sixtbook.sixt.com',
  development: 'sfdc.sixt.com',
}
export const cookieDomain = isInLocalHost
  ? 'localhost'
  : cookieConstants[process.env.REACT_APP_ENV_TYPE || 'development']
