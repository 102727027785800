import * as React from 'react'
import './Loader.scss'

interface ILoaderProps {
  isInContainer?: boolean
}

const Loader = (props?: ILoaderProps) => {
  return (
    <React.Fragment>
      <div
        className={
          props && props.isInContainer
            ? 'spinner-container-in-container'
            : 'spinner-container'
        }
      >
        <div
          className={
            props && props.isInContainer ? 'spinner-in-container' : 'spinner'
          }
        >
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Loader
