import gql from 'graphql-tag.macro'

export const sixtFbNewsIdsQuery = gql`
  query ($targetId: String) {
    getSixtFbPostIds(targetId: $targetId) {
      fbPage
      showHide
      id
    }
  }
`
export const sixtFbBatch = gql`
  mutation runFBBatch($targetId: String) {
    runFBBatch(targetId: $targetId) {
      status
    }
  }
`
