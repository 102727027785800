import * as React from 'react'
import {withRouter} from 'react-router-dom'

const ScrollToTop = ({children, location: {pathname}}: any) => {
  React.useEffect(() => {
    if (
      document
        .getElementsByTagName('html')[0]
        .classList.contains('is-mobile') &&
      pathname.indexOf('profile') > -1 &&
      document.getElementsByClassName('profile-body').length
    ) {
      document.getElementsByClassName('profile-body')[0].scroll(0, 0)
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return children || null
}

export default withRouter(ScrollToTop)
