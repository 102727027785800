import * as React from 'react'
import './App.scss'
import './commonStyles.scss'

import {BrowserRouter as Router} from 'react-router-dom'
import {ApolloProvider} from 'react-apollo'
import {MuiThemeProvider} from '@material-ui/core'
import materialTheme from './styles/materialTheme'
import RouteContainer from './container/RouteContainer/RouteContainer'
import {isMobile} from './utils/genericUtils'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
class App extends React.Component<any, any> {
  public componentDidMount() {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('class', isMobile() ? 'is-mobile' : 'is-desktop')
  }

  public render() {
    return (
      <div>
        <MuiThemeProvider theme={materialTheme}>
          <ApolloProvider client={this.props.client}>
            <Router>
              <ScrollToTop>
                <RouteContainer />
              </ScrollToTop>
            </Router>
          </ApolloProvider>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default App
