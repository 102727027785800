export const sfBaseUrl =
  process.env.REACT_APP_ENV_TYPE === 'production'
    ? 'https://sixt.my.salesforce.com'
    : 'https://sixt--qa.sandbox.my.salesforce.com'
export const orghierarchyUrl = sfBaseUrl + '/apex/sfapp#/orghierarchy/' // change to /orgchart/ on Org Hierarchy GOLIVE
export const attachmentViewUrl =
  sfBaseUrl + '/servlet/servlet.FileDownload?file='
export const hrAppProfileUrl =
  sfBaseUrl + '/apex/HRApp#/HREmployeeDetails?employeeId='
export const sixtFbAdminURL = `${sfBaseUrl}${
  process.env.REACT_APP_ENV_TYPE === 'production'
    ? '/aMN'
    : '/aN1?fcf=00B5E0000036obR'
}`
export const releaseNotesURL =
  'https://confluence.sixt.com/display/SXBOOK/SIXTbook+-+Release+Notes'
