import * as React from 'react'
import {compose, graphql, withApollo} from 'react-apollo'
import Header from '../../components/Header/Header'
import MobileBottomNav from '../../components/MobileBottomNav/MobileBottomNav'
import {
  currentUserRolesQuery,
  employeeDetailsQueryFromServer,
  getEmployeeDetailsFromIAM,
} from '../../queries/employeeQuery'
import RouteHandler from './RouteHandler'
import Loader from '../../components/common/Loader/Loader'
import {IProps} from './RouteContainer.d'
import {writeToCache} from '../../utils/graphQLUtils'
import {targetPropTypes} from '../../commonPropsType'
import {mappingtargetId} from './utils.RouteContainer'
import {withContext} from 'recompose'
import {SearchContext, searchState, searchReducer} from '../Search/utils.search'
import {newTargetGroupQuery} from '../../queries/newTargetGroupQuery'
import {getUserProfileInfo} from '../../queries/userProfileQuery'
import './routeContainer.scss'
import CookieConsentWrapper from '../../components/common/CookieConsentWrapper/CookieConsentWrapper'
import {getCookie, checkObjProps, isMobile} from '../../utils/genericUtils'
import {getLoginAsInfoFromCookie} from '../../utils/cookieUtils'

const RouteContainer = (props: IProps) => {
  const {
    dataError = null,
    dataLoading = false,
    targetLoading = false,
    userLoading = false,
    employee,
  } = props
  const [searchData, dispatchToSearch] = React.useReducer(
    searchReducer,
    searchState
  )

  React.useEffect(() => {
    if (employee) {
      writeToCache(employee)
    }
  }, [employee])

  return (
    <div>
      {dataError && `Error! ${dataError}`}
      {targetLoading || dataLoading || userLoading ? (
        <Loader />
      ) : (
        !!employee && (
          <div className="route-container">
            <SearchContext.Provider value={{searchData, dispatchToSearch}}>
              <Header />
              <RouteHandler employee={employee} />
              {isMobile() && <MobileBottomNav employee={employee} />}
              <CookieConsentWrapper citrixId={employee.citrixId} />
            </SearchContext.Provider>
          </div>
        )
      )}
    </div>
  )
}

const withTargetContext = withContext(targetPropTypes, (props: any) => {
  const {employee, isCommonUser, isInfluencer, isWorkdayEnabled} = props
  const {
    sxClient = '',
    sxRegion = '',
    citrixId = '',
    email = '',
    contractCountry = '',
  } = employee || {}
  const getDefaultTId = mappingtargetId(props.tIds)
  const {profilePhotoURL = '', name = ''} = props.adfsUserInfo || {}
  const defaultTarget =
    sxClient && sxRegion
      ? getDefaultTId[sxClient.concat(sxRegion)] ||
        (props.tIds && props.tIds['Headquarters Pullach']) ||
        '53'
      : (props.tIds && props.tIds.Franchise) || '52'
  const regionIdentifier = sxClient && sxRegion && sxClient.concat(sxRegion)
  const adfsUserInfo = {profilePhotoURL, name}
  const loginAsInfo = getLoginAsInfoFromCookie()
  const additionalTargets = []
  const userInfo = getCookie('loginUserInfo')
  const decodedUserInfo = JSON.parse(decodeURIComponent(userInfo))
  const emailFromLogin = decodedUserInfo.emailId || ''
  if (employee && employee.additionalTargetRegions && props && props.tIds) {
    const targetArr = employee.additionalTargetRegions.split(';')
    targetArr.forEach((item: string) => {
      if (props.tIds[item]) {
        additionalTargets.push(props.tIds[item])
      }
    })
  }
  return {
    citrixId: loginAsInfo.citrixId || citrixId,
    email: loginAsInfo.email || emailFromLogin || email,
    defaultTarget,
    regionIdentifier,
    adfsUserInfo,
    isCommonUser,
    additionalTargets,
    contractCountry,
    isInfluencer,
    isWorkdayEnabled: isWorkdayEnabled === 'true',
  }
})

export default compose(
  withApollo,
  graphql(newTargetGroupQuery, {
    props: ({data: {taxonomyTermQuery = {}, loading}}: any) => {
      const tIds = {}
      if (taxonomyTermQuery && taxonomyTermQuery.entities) {
        taxonomyTermQuery.entities
          .filter(entity => entity !== null)
          .forEach((item: any) => {
            if (item) {
              tIds[item.entityLabel] = item.entityId
            }
          })
      }
      return {tIds, targetLoading: loading}
    },
  }),
  graphql(employeeDetailsQueryFromServer, {
    options: props => ({variables: {empId: null}}),
    props: ({data, props}: any, lastProps: any) => {
      const {error, loading, employee = null, sxSFUsername, sxPayroll} = data
      return {
        dataError: error,
        dataLoading: loading,
        employee: employee || (lastProps && lastProps.employee),
        isCommonUser:
          (!sxSFUsername && employee && !employee.sfUserName) ||
          String(sxPayroll) === '9', // get from apollo cache
      }
    },
  }),
  graphql(getUserProfileInfo, {
    options: (props: any) => {
      const userInfo = getCookie('loginUserInfo')
      let emailId = getLoginAsInfoFromCookie().email
      if (!emailId && userInfo && decodeURIComponent(userInfo)) {
        emailId = JSON.parse(decodeURIComponent(userInfo)).emailId
      }
      return {variables: {emailIdList: [emailId]}}
    },
    props: ({data: {error, loading, getBulkUserProfileInfo}}: any) => {
      return {
        dataError: error,
        userLoading: loading,
        adfsUserInfo: checkObjProps(getBulkUserProfileInfo, ['0'], ''),
      }
    },
  }),
  graphql(getEmployeeDetailsFromIAM, {
    options: () => {
      const isLoggednIn = getLoginAsInfoFromCookie().citrixId
      const userInfo = isLoggednIn
        ? isLoggednIn
        : JSON.parse(decodeURIComponent(getCookie('loginUserInfo'))).userId
      return {variables: {empId: userInfo}}
    },
    props: ({data}: any) => {
      const {error, loading} = data
      return {
        isWorkdayEnabled: data.getEmployeeDetailsFromIAM
          ? data.getEmployeeDetailsFromIAM.isWorkdayEnabled
          : false,
        dataError: error,
        userLoading: loading,
      }
    },
  }),
  graphql(currentUserRolesQuery, {
    props: ({data}: any) => {
      const {error, loading, currentUserContext = {}} = data
      const currentUserRoles = checkObjProps(
        currentUserContext,
        ['roles'],
        []
      ).map(role => role.targetId)
      return {
        isInfluencer: currentUserRoles.includes('sixt_voice'),
        userLoading: loading,
      }
    },
  }),
  withTargetContext
)(RouteContainer)
