import moment from 'moment-timezone'
import * as momentTZ from 'moment-timezone'
import 'moment-precise-range-plugin'
import {useRef, useEffect} from 'react'
import {salesforceDateFormat} from '../constants/commonConstants'
import {getDateFormat} from './dateUtils'
import {
  backendURLs,
  urls,
  sixtbookUrl,
  backendHost,
} from '../constants/graphqlConstants'
import {intlNumbFormat} from './formatting'
import {INotification} from '../components/common/SXTNotification/SXTNotification.d'
import clonedeep from 'lodash.clonedeep'
import sanitizeHtml from 'sanitize-html'

/**
 * returns a parameter value of an object.
 * use this when you need to check value in deeply nested object, return value of an object or null .
 * ex -: if we want to check conditions (obj.data && obj.data.citrixId) and return value,
 * we can use checkObjProps(obj, ["data", "citrixId"]);
 *
 * @param {Object}   obj    object to be parsed
 * @param {Array}   propsArray    Array of property names
 * @returns {any}  value of the object property
 */

export const checkObjProps = (
  obj: any,
  propsArray: any,
  nullReturn: [] | {} | '' | null = null
) => {
  if (
    !obj ||
    (propsArray && propsArray.length === 0) ||
    !Array.isArray(propsArray)
  ) {
    return nullReturn
  }
  let objCopy = Object.assign({}, obj)

  for (const props of propsArray) {
    if (typeof objCopy[props] !== 'undefined' && objCopy[props] !== null) {
      objCopy = objCopy[props]
    } else {
      return nullReturn
    }
  }

  return objCopy
}

/**
 * returns a parameter date formating.
 * use this when you need to format date for send api calls,.
 * ex -: if we want to format date (Tue Feb 05 2019 09:41:40 GMT+0100 (Central European Standard Time)) and return value(2008-06-30),
 * we can use dateFormat(Tue Feb 05 2019 09:41:40 GMT+0100 (Central European Standard Time));
 *
 * @param {String or Date}   string    date
 */
export const dateFormat = (dateData: any) => {
  if (dateData instanceof Date) {
    dateData = dateData.toISOString()
  } else {
    dateData = new Date(dateData).toISOString()
  }
  return dateData.substring(0, dateData.indexOf('T'))
}

/**
 * returns a number of years between from date and to date
 * use this when you need to get the difference between two dates as number of years
 *
 * @param {date1}   string    first date to parse
 * @param {date2}   string    second date to parse
 * @returns {number}  number of years between date1 and date2
 */
export const getDifferenceInDatesAsYears: any = (
  date1: string,
  date2: string
) => {
  return getDifferenceInDates(date1, date2, 'year')
}

/**
 * returns a number (which maybe yaer, month or day) between from date and to date
 * use this when you need to get the difference between two dates as number (which maybe yaer, month or day)
 *
 * @param {date1}   string    first date to parse
 * @param {date2}   string    second date to parse
 * @param {format}   string    format (which maybe year, month or day)
 * @returns {number}  number (which maybe yaer, month or day) between date1 and date2
 */
export const getDifferenceInDates: any = (
  date1: string,
  date2: string,
  format: string
) => {
  if (format === 'year') {
    return (
      new Date(
        (date2 ? new Date(date2).getTime() : new Date().getTime()) -
          new Date(date1).getTime()
      ).getFullYear() - 1970
    )
  } else {
    return null // returning null as of now. Will change it in future
  }
}

/**
 * formats the date to DDMMYYYY format
 *
 * @param {dateString}   string    string date to format to DDMMYYYY
 * @returns {string}  formated date value as opposed to dateString passed
 */
export const formatDateStringToDDMMYYYY = (dateString: any) => {
  if (!dateString) {
    return ''
  }
  const dateFormat = getDateFormat()
  const newDate = moment(dateString, 'YYYY-MM-DD').format(dateFormat)
  return newDate
}

/**
 * formats the string to date Object
 *
 * @param {dateString}   string    string date("DDMMYYYY") format to date object
 * @returns {dateObject}  formated date value as instance of Date
 */
export const formatDateStringToObject = (dateString: any) => {
  if (!dateString) {
    return ''
  }
  const dateFormat = getDateFormat()
  return moment(dateString, dateFormat).toDate()
}

/**
 * formats the date to DDMMYYYY HH:MM format
 *
 * @param {dateString}   string    string date to format to DDMMYYYY
 * @returns {string}  formated date value as opposed to dateString passed
 */
export const formatToDateTimeString = (dateString: any) => {
  if (!dateString) {
    return ''
  }
  const dateFormat = getDateFormat()
  const defaultTimeZone = momentTZ.tz.guess(true)
  return moment
    .utc(dateString)
    .tz(defaultTimeZone)
    .format(dateFormat + ' HH:mm')
}

/**
 *
 * @param dateString string    string date to format to DDMMYYYY
 * @returns {string}  formated date value as opposed to dateString passed without time
 */
export const formatToDateTimeStringWithoutTime = (dateString: any) => {
  if (!dateString) {
    return ''
  }
  const dateFormat = getDateFormat()
  const defaultTimeZone = momentTZ.tz.guess(true)
  return moment.utc(dateString).tz(defaultTimeZone).format(dateFormat)
}

/**
 * Get utc timestamp in number and returns formatted date string
 */
export const formatUTCTimestamp = (timestamp: number) => {
  const dateFormat = getDateFormat()
  return moment(new Date(timestamp * 1000)).format(dateFormat + ' HH:mm')
}

/**
 * Get utc timestamp in number and returns formatted date string
 */
export const formatUTCTimestampWithDateFormat = (
  timestamp: number,
  format: string
) => {
  return moment(new Date(timestamp)).format(format)
}

/**
 * convert date to Salesforce specific format
 *
 * @param {dateString}   any    string date to format
 * @returns {string}  converted date as opposed to the passed in date value
 */
export const convertDateToStringInSalesforceFormat = (dateString: any) => {
  if (dateString) {
    if (dateString instanceof Date) {
      return moment(dateString).format(salesforceDateFormat)
    } else {
      return dateString
    }
  }
  return null
}

/**
 * get the payroll closing day as opposed to the date passed
 *
 * @param {dayNumber}   number    actual number to pass to get the payroll closing day
 * @returns {date}  the payroll closing day as opposed to the date passed
 */
export const getPayrollClosingDate = (dayNumber: number) => {
  const currentDate = new Date()
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), dayNumber)
}

/**
 * returns a boolean.
 * use this when you need to device detect as mobile,.
 * ex -: if we want to get the device is mobile, use isMobile(),if device detected as mobile, then return true,
 * we can use dateFormat(Tue Feb 05 2019 09:41:40 GMT+0100 (Central European Standard Time));
 *
 */
export const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    localStorage.setItem('mobileUser', 'true')
    return true
  } else {
    localStorage.setItem('mobileUser', 'false')
    return false
  }
}

/**
 * checks whether the object with arrays is empty
 *
 * @param {objectArray}   any    object with arrays
 * @returns {boolean}  checks whether all the arrays in object is empty
 */
export const isObjectWithEmptyArray = (objectArray: any) => {
  const reducedArray: any =
    Object.values(objectArray) &&
    Object.values(objectArray).reduce((accumulator: any, currentValue: any) =>
      currentValue && currentValue instanceof Array
        ? (accumulator && accumulator.concat(currentValue)) || currentValue
        : accumulator
    )

  return !reducedArray || !reducedArray.length || false
}

/**
 *   This function will be called whenever we need to throttle an action.
 *   Some use case is throttling an onchange event(the onchange event handler will be called for every certain intervals)
 *   Usage:
 *   document.addEventListener("eventName", Utils.throttle(300, this.functionToBeCalledAfterThrottling))
 *
 *   @param {delay}   number delay duration in milliseconds after which it will be throttled
 *   @param {callback} function which has to be called after throttling
 */

export function throttle(delay: number, callback: any) {
  let previousCall = new Date().getTime()
  return (...args: Array<[any]>) => {
    const time = new Date().getTime()
    if (time - previousCall >= delay) {
      previousCall = time
      callback.apply(null, args)
    }
  }
}

/**
 *   This function will be called whenever we need to debounce an action.
 *   Some use case is debouncing a scroll event(the scroll event handler will be called when the timeout delay is over)
 *   Usage:
 *   document.addEventListener("scroll", Utils.debounce(300, this.functionToBeCalledAfterDebouncing))
 *
 *   @param {delay}   number delay duration in milliseconds after which it will be debounced
 *   @param {callback} function which has to be called after debouncing
 */
export function debounce(delay: number, callback: any) {
  let timeout: any = null
  return (event: any) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      callback.apply(null, [event])
      timeout = null
    }, delay)
  }
}
/**
 * Returns true if the scroll has reached the bottom of the window
 * @returns {isReachedBottom} boolean value which denotes whether
 * the function has reached the bottom or not
 */
export function didScrollReachedBottom() {
  const windowHeight =
    'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight
  const body = document.body
  const html = document.documentElement
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
  const windowBottom = windowHeight + window.pageYOffset + 300
  return windowBottom >= docHeight
}

export const didScrollReachedBottomOfDiv = (divElement: HTMLElement) => {
  return (
    divElement &&
    divElement.scrollHeight <=
      divElement.offsetHeight + divElement.scrollTop + 10
  )
}

export const isInViewport = (element: any) => {
  const bounding = element.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Returns whether an object is empty or not
 */
export function isEmpty(object: any) {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

/**
 * Returns whether an object is equal or not
 */
export function isEqual(value: any, other: any) {
  // Get the value type
  const type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
    return false
  }

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
    return false
  }

  // Compare the length of the length of the two items
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) {
    return false
  }

  // Compare two items
  const compare = (item1: any, item2: any) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) {
        return false
      }
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) {
        return false
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          return false
        }
      } else {
        if (item1 !== item2) {
          return false
        }
      }
    }
    return true
  }

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) {
        return false
      }
    }
  } else {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) {
          return false
        }
      }
    }
  }

  // If nothing failed, return true
  return true
}

/**
 *   This function will be called whenever we need to modify the string to convert a camel case to separated case
 *
 *   @param {input}   string string which is in camelcase
 *   @returns {string}  separated case letters from camel case
 */
export const getCapitalizedValue = (inputString: string) => {
  const alteredInputString = inputString
    .replace(/\.?([A-Z])/g, (x, y) => ' ' + y)
    .replace(/^_/, '')
  return (
    alteredInputString.charAt(0).toUpperCase() + alteredInputString.slice(1)
  )
}

/**
 *   Custom hook built to make the component able to use previous props or state variables
 *
 *   @param {value}   object props or state value to be passed so that we can get the previous props
 *   @returns {object}  return previous props or state based on the value being passed
 */
export const usePrevious = (value: any): any => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const getNavTo = (citrixId: any) =>
  citrixId ? `/view-employee/${citrixId}` : '/profile'

export const getContractId = (match: any) =>
  checkObjProps(match, ['params', 'contractId'])

export const scrollToTop = () => {
  document.body.scrollTop = 0
  window.scrollTo(0, 0)
}

/**
 *   Comparing valid from date with payrollClosingDate
 *
 *   @param {Date, number}   From Date and Closing number
 *   @return boolean   return boolean value which denotes whether
 *    the From date compared with payrollClosingDate
 */
export const isPayrollCrossed = (validFrom: Date, closingDay: number) => {
  const currentDate = new Date()
  const payrollClosingDate = getPayrollClosingDate(closingDay)
  // return boolean
  return (
    validFrom.getFullYear() < currentDate.getFullYear() ||
    validFrom.getMonth() < currentDate.getMonth() ||
    payrollClosingDate < currentDate
  )
}

/**
 * Returns whether the article is liked by the user
 */
export const isArticleLikedByCurrentUser = (likes: any, citrixId: string) => {
  for (const like of likes) {
    const uid = checkObjProps(like, ['uid', 'entity', 'name'], '')
    if (uid === citrixId) {
      return true
    }
  }

  return false
}

export const getUrlParameter = (name: string) => {
  // eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const checkForSixtCompanyWorking = (companyName: string) => {
  return companyName.toLowerCase().indexOf('sixt') > -1
}

// This will give comma seperated numbers
export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 *
 * @param data The summary of the question
 * @returns Updated data based on the
 */
export const getLimitedText = (data: any, malxLength: number) => {
  let tempData = data
  if (data && data.length && data.length > malxLength) {
    tempData = data.substring(0, malxLength - 2) + '...'
  }
  return tempData
}

/**
 *
 * @param name full name which to be split
 * @param requiredData requred info like firstname or lastname
 * @returns string based on required data
 */
export const getFirstNameAndLastName = (
  name: string = '',
  requiredData: string
) => {
  const nameArray = name.split(' ')
  const firstName = nameArray[0] || ''
  const lastName = nameArray[nameArray.length - 1] || ''
  const requiredValue = requiredData === 'firstName' ? firstName : lastName
  return requiredValue
}

export const getCookie = (cname: string) => {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let obj of ca) {
    while (obj.charAt(0) === ' ') {
      obj = obj.substring(1)
    }
    if (obj.indexOf(name) === 0) {
      return obj.substring(name.length, obj.length)
    }
  }

  return ''
}

export const replaceImageUrls = (images = []) => {
  if (images && images.length) {
    return images.map((eachImage: any) => {
      if (eachImage && eachImage.url) {
        let {url = ''} = eachImage
        if (url && url.indexOf('https://') < 0) {
          url = 'https:' + url
        }
        return {
          ...eachImage,
          url:
            (url &&
              url.replace(
                backendURLs[process.env.REACT_APP_ENV_TYPE || 'development'],
                urls[process.env.REACT_APP_ENV_TYPE || 'development']
              )) ||
            '',
        }
      } else if (eachImage && typeof eachImage === 'string') {
        return replaceSingleImageUrl(eachImage)
      }
      return null
    })
  }
  return []
}

export const replaceSingleImageUrl = (url: string) => {
  if (url && url.indexOf('https://') < 0) {
    url = 'https:' + url
  }
  return (
    (url &&
      url.replace(
        backendURLs[process.env.REACT_APP_ENV_TYPE || 'development'],
        urls[process.env.REACT_APP_ENV_TYPE || 'development']
      )) ||
    ''
  )
}

/* Replace drupal link with sixtbook backend urls, to avoid login redirects */
export const replaceFeedPictureUrl = (
  picture: string,
  allowedTags: string[] | boolean = ['picture', 'img', 'source']
) => {
  return {
    __html: sanitizeHtml(
      (picture &&
        picture.replaceAll(
          backendHost[process.env.REACT_APP_ENV_TYPE || 'development'],
          sixtbookUrl[process.env.REACT_APP_ENV_TYPE || 'development']
        )) ||
        '',
      {
        allowedTags,
        allowedAttributes: false,
      }
    ),
  }
}

export const linkifyUrl = (text: any, newWindow: boolean) => {
  if (!text) {
    return ''
  }
  const textUpdated = text.replace(/(\&nbsp\;)/g, ' ')
  const urlPattern =
    /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w_=-]*)?\??(?:[-\+=&;%@.,|\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi
  const target = newWindow === true || newWindow == null ? '_blank' : ''
  return textUpdated.replace(urlPattern, (url: any) => {
    const protocolPattern = /^(?:(?:https?|ftp):\/\/)/i
    const href = protocolPattern.test(url) ? url : 'http://' + url
    return '<a href="' + href + '" target="' + target + '">' + url + '</a>'
  })
}

export const monthDayDifference = (startDate: any) => {
  const start = moment(startDate)
  const end = moment()
  const diff = moment.preciseDiff(start, end, true)
  let yearMonth
  if (diff.months > 0) {
    const month = parseFloat((diff.months / 12).toFixed(1))
    yearMonth = diff.years + month
  } else {
    yearMonth = diff.years
  }
  return intlNumbFormat(yearMonth, 1)
}

export const actOnVideo = (feeds: Element[], action: string) => {
  feeds.forEach((eachFeed: Element) => {
    const iframeElements = eachFeed.getElementsByTagName('iframe')
    if (iframeElements && iframeElements.length) {
      const iframe = iframeElements[0]
      if (iframe.src.includes('vimeo') || iframe.src.includes('youtube')) {
        const command = iframe.src.includes('vimeo')
          ? {
              method: action,
              value: true,
            }
          : {
              event: 'command',
              func: action + 'Video',
            }
        iframe.contentWindow.postMessage(JSON.stringify(command), '*')
      }
    }
  })
}

export const getNotificationsOnSubscription = (
  notifications: INotification[],
  newNotifications: INotification[]
) => {
  let notificationsToChange: INotification[] = []
  if (notifications && notifications.length) {
    if (newNotifications && newNotifications.length) {
      const notificationIds = newNotifications.map(
        (eachNotification: INotification) => eachNotification.id
      )
      notificationsToChange = [
        ...newNotifications,
        ...notifications.filter(
          eachNotification => !notificationIds.includes(eachNotification.id)
        ),
      ]
    } else {
      notificationsToChange = newNotifications
    }
  }
  return notificationsToChange
}

/**
 *
 * @param currentLikeText string: reaction type
 * @returns string: Reaction name
 */
export const getReactionName = currentLikeText => {
  if (currentLikeText === 'wow') {
    return 'Surprised'
  } else if (currentLikeText === 'sad') {
    return 'Displeased'
  } else {
    return currentLikeText
  }
}
/**
 *
 * @param list array: list of object
 * @param key string: key of object
 * @returns object: group by object with array
 */
export const groupBy = (list, key) => {
  return list.reduce((item, x) => {
    ;(item[x[key]] = item[x[key]] || []).push(x)
    return item
  }, {})
}

/**
 *
 * @returns object: node types, elastisearch type mapping
 */
export const nodeTypeMap = {
  NodeNews: 'news',
  NodeWiki: 'wiki',
  NodeSocialPost: 'socialnews',
  NodeHotel: 'hotel',
  NodeAirline: 'airline',
}
export const getListDiff = (newList = [], existingList = []) => {
  const resultA =
    existingList &&
    existingList.filter(
      datum =>
        newList &&
        !newList
          .map(item => JSON.stringify(item))
          .includes(JSON.stringify(datum))
    )
  const resultB =
    newList &&
    newList.filter(
      datum =>
        existingList &&
        !existingList
          .map(item => JSON.stringify(item))
          .includes(JSON.stringify(datum))
    )

  return [...resultA, ...resultB]
}

const validateNumber = (modalData: any, api: any) => {
  return (
    Number(modalData[api.apiName]) > Number(api.max) ||
    Number(modalData[api.apiName]) < Number(api.min) ||
    (api.decimalPoint > 0 &&
      (modalData[api.apiName].split('.')[1] || '').length > api.decimalPoint)
  )
}

export const isFormValid = (modalData, actualAPIList) => {
  const errorList =
    (actualAPIList &&
      actualAPIList.filter(
        api =>
          api.hasError ||
          (api.isRequired &&
            (!modalData[api.apiName] ||
              (['text', 'textarea'].includes(api.dataType || 'text') &&
                !modalData[api.apiName].trim()) ||
              (api.selectGraphqlTextType === 'number' &&
                validateNumber(modalData, api)))) ||
          (api.dataType === 'file-upload' &&
            api.isRequired &&
            Array.isArray(modalData[api.apiName]) &&
            modalData[api.apiName].length <= 0)
      )) ||
    []

  return errorList.length === 0
}
