import * as React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {Switch} from 'react-router'
import Loader from '../../components/common/Loader/Loader'
import HealthCheck from '../../components/HealthCheck/HealthCheck'
import Logout from '../../components/Logout/Logout'
import {checkObjProps} from '../../utils/genericUtils'
const Home = React.lazy(() => import('../Home/Home'))
const WorkInProgress = React.lazy(() => import('../Home/WorkInProgress'))
const Profile = React.lazy(() => import('../Profile/Profile'))
const NodeArticle = React.lazy(() => import('../NodeArticle/NodeArticle'))
const Wiki = React.lazy(() => import('../Wiki/Wiki'))
const Restaurant = React.lazy(() => import('../Restaurant/Restaurant'))
const ThinkTank = React.lazy(() => import('../ThinkTank/ThinkTank'))
const WorkSpace = React.lazy(() => import('../WorkSpace/WorkSpace'))
const NotFound = React.lazy(() => import('../404/NotFound'))
const Notification = React.lazy(() => import('../Notification/Notification'))
const Bureaucracy = React.lazy(() => import('../Bureaucracy/Bureaucracy'))
const DirectlyTo = React.lazy(() => import('../DirectlyTo/DirectlyTo'))
const Search = React.lazy(() => import('../Search/Search'))
const CookieConsentPolicy = React.lazy(
  () => import('../../components/CookieConsentPolicy/CookieConsentPolicy')
)
const RedirectToHome = () => <Redirect to="/home" />
const RedirectToNotFound = () => <Redirect to="/404" />

class RouteHandler extends React.PureComponent<any> {
  public render() {
    return (
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact={true} render={RedirectToHome} />
          <Route path="/home" component={Home} />
          <Route path="/profile" render={() => <Profile />} />
          <Route
            path="/view-employee/:citrixId"
            render={({match}) => (
              <Profile
                citrixId={checkObjProps(match, ['params', 'citrixId'], '')}
              />
            )}
          />
          <Route path="/health" component={HealthCheck} />
          <Route path="/news/:nid" component={NodeArticle} />
          <Route path="/socialnews/:nid" component={NodeArticle} />
          <Route path="/hotel/:nid" component={NodeArticle} />
          <Route path="/airline/:nid" component={NodeArticle} />
          <Route path="/wiki" component={Wiki} />
          <Route path="/think-tank/:page" component={ThinkTank} />
          <Route path="/restaurant" component={Restaurant} />
          <Route path="/logout" component={Logout} />
          <Route path="/work-space" component={WorkSpace} />
          <Route path="/notification" component={Notification} />
          <Route path="/wip" component={WorkInProgress} />
          <Route path="/bureaucracy" component={Bureaucracy} />
          <Route path="/directly-to" component={DirectlyTo} exact={true} />
          <Route
            path="/directly-to/:type/:id"
            component={DirectlyTo}
            exact={true}
          />
          <Route
            path="/directly-to/:type"
            component={DirectlyTo}
            exact={true}
          />
          <Route path="/search" component={Search} />
          <Route path="/node/:nid" component={NodeArticle} />
          <Route path="/cookies-consent" component={CookieConsentPolicy} />
          <Route
            path="/404"
            render={props => (
              <NotFound
                header="OH NO! THIS PAGE DOESN'T EXIST OR WAS REMOVED"
                paragraph="We are sorry! The page you were searching for cannot be found."
                {...props}
              />
            )}
          />
          <Route render={RedirectToNotFound} />
        </Switch>
      </React.Suspense>
    )
  }
}

export default RouteHandler
