/**
 * SXTNotificationList - to display notifications which includes SXTNotification
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickShanmugam0689)
 */
import * as React from 'react'
import {IListProps, INotification} from './SXTNotification.d'
import ReactLazyLoad from 'react-lazyload'
import SXTNotification from './SXTNotification'
import SXTEmptyData from './SXTEmptyData'
const SXTNotificationList = (props: IListProps) => {
  const {notifications, showTag, isLighterView, isLoading} = props

  return (
    <>
      {notifications && notifications.length > 0
        ? notifications.map((notification: INotification, index: number) => (
            <ReactLazyLoad throttle={10} height={65} key={index}>
              <SXTNotification
                notification={notification}
                showTag={showTag}
                isLighterView={isLighterView}
              />
            </ReactLazyLoad>
          ))
        : !isLoading && <SXTEmptyData />}
    </>
  )
}

export default React.memo(SXTNotificationList)
