import * as React from 'react'
import {ACTION_TYPES} from '../../constants/articleTypesConstants'

export const searchState = {
  redirectSearch: false,
  searchTerm: '',
  suggestions: [],
  contactOption: {},
}
interface IActionPayload {
  payload: any
  type: string
}
interface IContextProps {
  dispatchToSearch: (obj: IActionPayload) => void
  searchData: IState
}

interface IState {
  redirectSearch: boolean
  searchTerm: string
  suggestions: any
}

export const SearchContext = React.createContext({} as IContextProps)

export const searchReducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      }
    }
    case ACTION_TYPES.SET_SEARCH_SUGGESTIONS: {
      return {
        ...state,
        suggestions: state.searchTerm.length > 0 ? action.payload : [],
      }
    }
    case ACTION_TYPES.SET_REDIRECT: {
      return {
        ...state,
        redirectSearch: action.payload,
      }
    }
    case ACTION_TYPES.SET_SEARCH_HISTORY_SUGGESTIONS: {
      return {
        ...state,
        suggestions: action.payload,
      }
    }
    case ACTION_TYPES.SET_CONTACT_OPTION: {
      return {
        ...state,
        contactOption: action.payload,
        searchTerm: action.clearSearchTerm ? '' : state.searchTerm,
      }
    }
    default:
      return state
  }
}

export const searchFilterReducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SEARCH_FILTERS: {
      return {
        ...state,
        allFilters: action.payload,
      }
    }
    case ACTION_TYPES.SET_SEARCH_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.payload,
        },
      }
    }
    case ACTION_TYPES.CLEAR_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilters: {},
      }
    }
    case ACTION_TYPES.SET_MOB_SEARCH_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilters: action.payload,
      }
    }
    default:
      return state
  }
}
