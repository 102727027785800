/**
 * SubMenu - Display App name with collapsible Sub Menu Details with Name
 * and redirecting router link
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'

import {NavLink} from 'react-router-dom'
import classNames from 'classnames'
import {ImageCollections} from '../../utils/imageImports'
import './sideBarPanel.scss'

class SubMenu extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      expanded: true,
    }
  }
  public collapsible = () => {
    const {expanded} = this.state
    this.setState({
      expanded: !expanded,
    })
  }
  public render() {
    const {list, onCollapse} = this.props
    const {expanded} = this.state
    return (
      <li>
        {list.iconName && (
          <img src={ImageCollections[list.iconName]} alt={list.name} />
        )}
        <button
          className={classNames('collapsible button-with-transparent', {
            expanded,
          })}
          data-testid="sub-menu-collapse"
          onClick={this.collapsible}
        >
          <strong>{list.name}</strong>
        </button>
        {expanded && (
          <ul>
            {list.subMenu &&
              list.subMenu.map((menu: any, i: string) => (
                <li key={i} onClick={onCollapse}>
                  {menu.iconName && (
                    <img
                      src={ImageCollections[menu.iconName]}
                      alt={menu.name}
                    />
                  )}
                  {menu.targetBlank ? (
                    <a target="_blank" href={menu.to} rel="noopener noreferrer">
                      {' '}
                      {menu.name}{' '}
                    </a>
                  ) : (
                    <NavLink to={menu.to}>{menu.name}</NavLink>
                  )}
                </li>
              ))}
          </ul>
        )}
      </li>
    )
  }
}
export default SubMenu
