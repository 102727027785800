import * as React from 'react'
import {compose, withApollo} from 'react-apollo'
import {
  getUserProfileInfo,
  getUserProfileInfoFromClient,
} from '../../../queries/userProfileQuery'
import {getContext} from 'recompose'
import {targetPropTypes} from '../../../commonPropsType'
import {checkObjProps} from '../../../utils/genericUtils'
import {Avatar} from '@material-ui/core'

const ADFSAvatar = (props: any) => {
  const [adfsUserInfo, setUserInfo] = React.useState({
    profilePhotoURL: '',
    name: '',
  })

  const {employeeName = '', isEmailIdValid = true} = props
  React.useEffect(
    () => {
      let ignore = false
      const getGraphUserDetails = async () => {
        let userResponse
        let arrayObj = ['getBulkUserProfileInfo', '0']
        const emailToFetch = props.emailId || props.email
        try {
          userResponse = await props.client.readQuery({
            query: getUserProfileInfoFromClient,
            variables: {emailIdList: [emailToFetch]},
          })
        } catch (err) {
          userResponse = null
        }
        if (!userResponse) {
          userResponse = await props.client.query({
            fetchPolicy:
              process.env.NODE_ENV === 'test' ? 'cache-first' : 'network-only',
            query: getUserProfileInfo,
            variables: {emailIdList: [emailToFetch]},
          })
          arrayObj = ['data', 'getBulkUserProfileInfo', '0']
        }
        const userInfo = checkObjProps(userResponse, arrayObj, {})
        if (!ignore) {
          setUserInfo(userInfo)
        }
      }
      if (isEmailIdValid) {
        getGraphUserDetails()
      }

      return () => {
        ignore = true
      }
    },
    // eslint-disable-next-line
    [props.emailId, props.adfsUserInfo]
  )

  const {profilePhotoURL = '', name = ''} = adfsUserInfo
  let namesplit = name ? name.split(' ') : []
  if (!namesplit.length) {
    namesplit = employeeName ? employeeName.split(' ') : []
  }

  return (
    <Avatar
      data-hj-suppress={true}
      alt={name}
      src={profilePhotoURL}
      className={props.className || 'profile-pic'}
    >
      {namesplit &&
        namesplit.length > 0 &&
        `${namesplit[0].slice(0, 1)}${namesplit[namesplit.length - 1].slice(
          0,
          1
        )}`}
    </Avatar>
  )
}

export default compose(getContext(targetPropTypes), withApollo)(ADFSAvatar)
