import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import {ApolloClient} from 'apollo-client'
import moment from 'moment'
import {graphqlConstants} from '../constants/graphqlConstants'
import {
  ERROR_MESSAGE,
  INTERNAL_SERVER_ERROR,
} from '../constants/commonConstants'
import {createUploadLink} from 'apollo-upload-client'
import {onError} from 'apollo-link-error'
import {setContext} from 'apollo-link-context'
import {RetryLink} from 'apollo-link-retry'
import {loginOnSessionTimeout, refreshToken} from '../utils/auth'
import {from, split} from 'apollo-link'
import {LocalStorageWrapper, persistCache} from 'apollo3-cache-persist'
import introspectionQueryResultData from '../fragmentTypes.json'
import {getCookie} from '../utils/genericUtils'
import {getMainDefinition} from 'apollo-utilities'

interface IDefinintion {
  kind: string
  operation?: string
}

export const createClient = async () => {
  const errorLink = onError(({graphQLErrors, networkError}) => {
    let isUnauthorized = false
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        if (
          error.extensions &&
          ((error.extensions.response &&
            error.extensions.response.status === 500) ||
            error.extensions.code === INTERNAL_SERVER_ERROR)
        ) {
          error.message = ERROR_MESSAGE
        } else if (
          error.extensions &&
          ((error.extensions.response &&
            error.extensions.response.status === 401) ||
            error.extensions.code === 'UNAUTHENTICATED')
        ) {
          isUnauthorized = true
        }
      })
    }
    if (networkError && networkError.message) {
      if (networkError.message.includes('401')) {
        isUnauthorized = true
      } else if (
        networkError.message.includes('400') ||
        networkError.message.includes('500')
      ) {
        networkError.message = ERROR_MESSAGE
      }
    }
    if (isUnauthorized) {
      loginOnSessionTimeout()
    }
  })
  const httpLink = createUploadLink({
    uri: graphqlConstants.endpoint,
    credentials: 'include',
  })

  // const wsLink = new WebSocketLink({
  //   uri: graphqlConstants.wsEndpoint,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       credentials: 'include',
  //     },
  //   },
  // })

  const link = split(({query}) => {
    const {kind}: IDefinintion = getMainDefinition(query)
    return kind === 'OperationDefinition'
  }, httpLink)

  const authLink = setContext((request, previousContext) => {
    const userInfo = getCookie('loginUserInfo')
    let isExpired = true
    let expiry = 0
    if (userInfo && decodeURIComponent(userInfo)) {
      const exp = JSON.parse(decodeURIComponent(userInfo)).exp
      expiry = Number(exp)
      isExpired = moment(expiry * 1000).isSameOrBefore(moment())
    }
    if (isExpired) {
      refreshToken()
    }
    return {
      headers: {
        isCommonUser: request.variables && request.variables.isCommonUser,
        'Cache-Control': 'no-cache',
        useSixtbookToken: request.variables && request.variables.usesixtbook,
      },
    }
  })

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  const retryLink = new RetryLink({
    attempts: {
      max: 10,
      retryIf: error => {
        // Retry on network errors or specific server-side errors (e.g., 503)
        return (
          error.networkError ||
          (error.graphQLErrors &&
            error.graphQLErrors.some(e => e.extensions.code === 'UNAVAILABLE'))
        )
      },
    },
    delay: {
      initial: 300,
      max: 5000,
      jitter: true,
    },
  })

  const cache = new InMemoryCache({fragmentMatcher})

  // This persist the cache in sessionStorage so that even if it is refreshed the cached values will be saved
  // await before instantiating ApolloClient, else queries might run before the cache is persisted
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.sessionStorage),
  })

  const userInfo = getCookie('loginUserInfo')
  let name = ''
  let email = ''
  if (userInfo && decodeURIComponent(userInfo)) {
    const userInfoObj = JSON.parse(decodeURIComponent(userInfo))
    const {
      firstName = '',
      lastName = '',
      emailId = '',
      sxSFUsername = '',
      sxPayroll = '',
    } = userInfoObj
    name = firstName + ' ' + lastName
    email = emailId
    cache.writeData({
      data: {
        email,
        name,
        sxSFUsername,
        sxPayroll,
      },
    })
  }

  const client = new ApolloClient({
    cache,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
      },
    },
    link: from([retryLink, authLink, errorLink, link]),
    resolvers: {
      Query: {
        email: (rootValue, args, context, info) => {
          return email
        },
        name: (rootValue, args, context, info) => {
          return name
        },
      },
    },

    queryDeduplication: true,
  })

  return client
}
