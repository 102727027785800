/**
 * LoginAsInfo - Information regarding loginas information
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickshanmugam0689)
 */
import * as React from 'react'
import {getLoginAsInfoFromCookie} from '../../utils/cookieUtils'

const LoginAsInfo = () => {
  const loginAsInfo = getLoginAsInfoFromCookie()
  return (
    <span className="login-as-info">
      {loginAsInfo.firstName &&
        `Logged in as ${loginAsInfo.firstName} ${loginAsInfo.lastName}`}
    </span>
  )
}

export default React.memo(LoginAsInfo)
