/**
 * SXTNotification - Display notification as part of header and notification center
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickshanmugam0689)
 */
import * as React from 'react'
import sanitizeHtml from 'sanitize-html'
import {IProps, INotificationTagProps} from './SXTNotification.d'
import './SXTNotification.scss'
import {formatToDateTimeString, isMobile} from '../../../utils/genericUtils'
import {ImageCollections} from '../../../utils/imageImports'
import ADFSAvatar from '../ADFSAvatar/ADFSAvatar'
import {IState} from './SXTNotification.d'
import {getUserProfileFromCitrixId} from '../../../utils/userUtils'
import {compose, withApollo} from 'react-apollo'
import classNames from 'classnames'
import Fade from '@material-ui/core/Fade'
import Tags from '../Tags/Tags'
import {
  getDrupalNotificationsCount,
  getNotificationCountsQuery,
  readNotificationMutation,
  saveAckNotificationMutation,
} from '../../../queries/notificationQuery'
import {getContext} from 'recompose'
import {targetPropTypes} from '../../../commonPropsType'

const getInitialUserInfo = (description: string, submittedBy: string) => {
  return {
    notificationDescription:
      description && !description.includes('citrixId:') ? description : '',
    submitterEmailId: '',
    submitterName: submittedBy || '',
  }
}

const NotificationTag = (props: INotificationTagProps) => {
  const {showTag, tag} = props

  return (
    <div className="notification-tag">
      {showTag && tag && (
        <div className="tip-container">
          <Tags tags={[props.tag]} />
        </div>
      )}
    </div>
  )
}

const SXTNotification = (props: IProps) => {
  const isInMobile = isMobile()

  const {
    notification,
    client,
    showTag,
    isLighterView,
    acknowledgeNotification,
    citrixId,
  } = props

  const {
    description,
    submittedDate = '',
    icon = '',
    redirectionURL,
    isRead,
    submitterCitrixId,
    submittedBy,
    tag,
    entityId,
    id,
    typeOfNotification,
  } = notification

  const [notificationInfo, setNotificationInfo] = React.useState<IState>(
    getInitialUserInfo(description, submittedBy)
  )

  const [isNotificationRead, setNotificationReadState] = React.useState(false)

  const readNotification = () => {
    if (typeOfNotification === 'Tasks') {
      readSFNotification()
    } else {
      readDrupalNotification()
    }
  }
  const readDrupalNotification = () => {
    client
      .mutate({
        mutation: readNotificationMutation,
        variables: {
          entity_id: entityId,
        },
        refetchQueries: [
          {
            query: getDrupalNotificationsCount,
          },
        ],
      })
      .then(() => {
        setNotificationReadState(true)
      })
  }
  const readSFNotification = () => {
    const notifications = [
      {
        id,
        isRead: true,
      },
    ]
    client
      .mutate({
        mutation: saveAckNotificationMutation,
        variables: {
          citrixId,
          notifications: JSON.stringify(notifications),
        },
        refetchQueries: [
          {
            query: getNotificationCountsQuery,
            variables: {empId: props.citrixId},
          },
        ],
      })
      .then(() => {
        setNotificationReadState(true)
      })
  }

  React.useEffect(() => {
    setNotificationReadState(isRead)
  }, [isRead])

  React.useEffect(
    () => {
      const getUserProfileInfo = async () => {
        const userInfo = await getUserProfileFromCitrixId(
          submitterCitrixId,
          client
        )
        setNotificationInfo({
          notificationDescription:
            description &&
            description.replace(
              `citrixId:${submitterCitrixId}`,
              userInfo.employeeName
            ),
          submitterEmailId: userInfo.employeeEmail,
          submitterName: userInfo.employeeName,
        })
      }
      if (
        submitterCitrixId &&
        (!icon || (description && description.includes('citrixId:')))
      ) {
        getUserProfileInfo()
      } else {
        setNotificationInfo(getInitialUserInfo(description, submittedBy))
      }
    }, // eslint-disable-next-line
    [submitterCitrixId, icon, description]
  )

  const {notificationDescription, submitterEmailId, submitterName} =
    notificationInfo

  const isInLighterView = isInMobile || isLighterView

  return (
    <Fade in={true} timeout={1000}>
      <a
        className={classNames('sxt-notification', {
          'un-read': !isNotificationRead,
          'light-view': isInLighterView,
        })}
        href={redirectionURL}
        onClick={readNotification}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="notification-icon">
          {!!icon ? (
            <div className="icon-container">
              <img
                src={ImageCollections[icon]}
                alt="notification icon"
                className="icon"
              />
            </div>
          ) : (
            submitterEmailId && (
              <ADFSAvatar
                emailId={submitterEmailId}
                employeeName={submitterName}
              />
            )
          )}
        </div>
        <div className="notification-description">
          {notificationDescription && (
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(notificationDescription, {
                  allowedTags: false,
                  allowedAttributes: false,
                }),
              }}
            />
          )}
          <div className="date">
            <img
              src={ImageCollections.ico_clock}
              alt="clock for time"
              className="clock"
            />
            <span className="date-text">
              {formatToDateTimeString(submittedDate)}
            </span>
          </div>
          {isInLighterView && <NotificationTag showTag={showTag} tag={tag} />}
        </div>
        {!isInLighterView && <NotificationTag showTag={showTag} tag={tag} />}
      </a>
    </Fade>
  )
}

export default compose(withApollo, getContext(targetPropTypes))(SXTNotification)
