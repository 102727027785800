import AlexanderSixt from '../assets/images/AlexanderSixt.svg'
import flag_german from '../assets/images/flag_german.svg'
import ico_arrow from '../assets/images/ico_arrow.svg'
import ico_arrow_orange from '../assets/images/ico_arrow_orange.svg'
import ico_arrowback from '../assets/images/ico_arrowback.svg'
import ico_arrowback_orange from '../assets/images/ico_arrowback_orange.svg'
import ico_bell from '../assets/images/ico_bell.svg'
import ico_bell_black from '../assets/images/ico_bell_black.svg'
import ico_building from '../assets/images/ico_building.svg'
import ico_bureaucracybutton from '../assets/images/ico_bureaucracybutton.svg'
import ico_businessbag from '../assets/images/ico_businessbag.svg'
import ico_calendar from '../assets/images/ico_calendar.svg'
import ico_camera from '../assets/images/ico_camera.svg'
import ico_chevrondown from '../assets/images/ico_chevrondown.svg'
import ico_circlearrow from '../assets/images/ico_circlearrow.svg'
import ico_confirmation from '../assets/images/ico_confirmation.png'
import ico_conversation from '../assets/images/ico_conversation.svg'
import ico_corporate from '../assets/images/ico_Corporate_icon.svg'
import ico_degree from '../assets/images/ico_degree.svg'
import ico_delete from '../assets/images/ico_delete.png'
import ico_directlyto from '../assets/images/ico_directlyto.svg'
import ico_eye from '../assets/images/ico_eye.svg'
import ico_handbag from '../assets/images/ico_handbag.svg'
import ico_headset from '../assets/images/ico_headset.svg'
import ico_home from '../assets/images/ico_home.svg'
import ico_house from '../assets/images/ico_house.svg'
import ico_it_support from '../assets/images/ico_it_support.svg'
import ico_licenseplate from '../assets/images/ico_licenseplate.svg'
import ico_location from '../assets/images/ico_location.svg'
import ico_mail from '../assets/images/ico_mail.svg'
import ico_map from '../assets/images/ico_map.svg'
import ico_markedcalendar from '../assets/images/ico_markedcalendar.svg'
import ico_marketplace from '../assets/images/ico_marketplace.svg'
import ico_pen from '../assets/images/ico_pen.svg'
import ico_pen_black from '../assets/images/ico_pen_black.svg'
import ico_people from '../assets/images/ico_people.svg'
import ico_phone from '../assets/images/ico_phone.svg'
import ico_plus from '../assets/images/ico_plus.svg'
import ico_plus_orange from '../assets/images/ico_plus_orange.svg'
import ico_profile from '../assets/images/ico_profile.svg'
import ico_sixt from '../assets/images/ico_sixt.svg'
import ico_smartphone from '../assets/images/ico_smartphone.svg'
import ico_star_filled from '../assets/images/ico_star_filled.svg'
import ico_think_tank from '../assets/images/ico_think_tank.svg'
import ico_think_tank_white from '../assets/images/ico_think_tank_white.svg'
import ico_trash from '../assets/images/ico_trash.svg'
import ico_tacho from '../assets/images/ico_tacho.svg'
import ico_upload from '../assets/images/ico_upload.svg'
import ico_wiki from '../assets/images/ico_wiki.svg'
import ico_wiki_white from '../assets/images/ico_wiki_white.svg'
import InnovationBadge from '../assets/images/InnovationBadge.svg'
import professionImage from '../assets/images/profession_otherCompany.svg'
import professionImageHover from '../assets/images/profession_sixt.svg'
import sixtbook_logo from '../assets/images/sixtbook_logo.svg'
import ThankYouBadge from '../assets/images/ThankYouBadge.svg'
import ico_workphone from '../assets/images/ico_workphone.svg'
import ico_comment from '../assets/images/ico_comment.svg'
import ico_boxchecked from '../assets/images/ico_boxchecked.svg'
import ico_connect from '../assets/images/ico_connect.svg'
import ico_star from '../assets/images/ico_star.svg'
import ico_star_grey from '../assets/images/ico_star_grey.svg'
import ico_like from '../assets/images/ico_like.svg'
import ico_liked from '../assets/images/ico_liked.svg'
import articles_default_image from '../assets/images/articles_default_image.jpg'
import ico_mobile_comments from '../assets/images/ico_mobile_comments.svg'
import ico_mobile_likes from '../assets/images/ico_mobile_likes.svg'
import ico_sales from '../assets/images/ico_sales.svg'
import ico_marker from '../assets/images/ico_marker.svg'
import ico_products from '../assets/images/ico_products.svg'
import ico_hr from '../assets/images/ico_hr.svg'
import ico_marketing from '../assets/images/icon-Marketing-Icon.svg'
import ico_station_process from '../assets/images/ico_station_process.svg'
import ico_ring_bell from '../assets/images/ico_ring_bell.svg'
import ico_tech from '../assets/images/ico_tech.svg'
import ico_dots from '../assets/images/ico_dots.svg'
import ico_dotsVertical from '../assets/images/ico_dots_vertical.svg'
import ico_list from '../assets/images/ico_list.svg'
import ico_info_box from '../assets/images/ico_info_box.svg'
import ico_facebook from '../assets/images/ico_facebook.svg'
import ico_share from '../assets/images/ico_share.svg'
import restaurant_logo from '../assets/images/restaurant_logo.svg'
import ico_network_error from '../assets/images/ico_network_error.svg'
import ico_magnifier from '../assets/images/ico_magnifier.svg'
import ico_share_linkedin from '../assets/images/ico_share_linkedin.svg'
import ico_share_facebook from '../assets/images/ico_share_facebook.svg'
import ico_share_orange from '../assets/images/ico_share_orange.svg'
import ico_share_sixt from '../assets/images/ico_share_sixt.svg'
import ico_logo_linkedin from '../assets/images/ico_logo_linkedin.png'
import wiki_icon from '../assets/images/wiki-icon.svg'
import wiki_ico_tags from '../assets/images/wiki-ico-tags.svg'
import ico_write_post from '../assets/images/personalize.svg'
import ico_mail_wiki from '../assets/images/ico_mail_wiki.svg'
import image_placeholder from '../assets/images/image_placeholder.svg'
import ico_language from '../assets/images/ico_language.svg'
import ico_phone2 from '../assets/images/ico_phone2.svg'
import ico_download from '../assets/images/ico_download.svg'
import Alexander from '../assets/images/AlexanderSixt.jpg'
import KonstantinSixt from '../assets/images/KonstantinSixt.jpg'
import NicoGabriel from '../assets/images/Nico_Gabriel.png'
import KaiAndrejewski from '../assets/images/KaiAndrejewski.png'
import bureaucracy_button from '../assets/images/bureaucracy_button.png'
import general_info from '../assets/images/emergency_safety.png'
import ico_influencer from '../assets/images/ico_influencer.svg'
import ico_costcenter from '../assets/images/ico_costcenter.svg'
import announcement from '../assets/images/announcement.svg'
import ico_rightMark from '../assets/images/ico_rightMark.svg'
import pdf_orange from '../assets/images/pdf_orange.svg'
import ico_chevron_right_orange from '../assets/images/ico_chevron_right_orange.svg'
import ico_arrow_out from '../assets/images/ico_arrow_out.svg'
import ico_clock from '../assets/images/ico_clock.svg'
import ico_additional_qualification from '../assets/images/Additional_Qualification_Ico.svg'
import ico_professional_activity from '../assets/images/professional_activity.svg'
import mobile_work_icon from '../assets/images/mobile_work_icon.png'
import serviceHotline_banner_sixtbook from '../assets/images/ServiceHotline_Banner_Sixtbook.png'
import VinzenzPflanz from '../assets/images/VinzenzPflanz.jpg'
import workday_inverted from '../assets/images/workday-inverted.png'
import our_culture from '../assets/images/Our Culture_Banner.png'

export const ImageCollections: {[index: string]: any} = {
  AlexanderSixt,
  InnovationBadge,
  ThankYouBadge,
  articles_default_image,
  flag_german,
  ico_arrow,
  ico_arrow_orange,
  ico_arrowback,
  ico_arrowback_orange,
  ico_bell,
  ico_bell_black,
  ico_boxchecked,
  ico_building,
  ico_bureaucracybutton,
  ico_businessbag,
  ico_calendar,
  ico_camera,
  ico_chevrondown,
  ico_circlearrow,
  ico_comment,
  ico_confirmation,
  ico_connect,
  ico_conversation,
  ico_corporate,
  ico_degree,
  ico_delete,
  ico_directlyto,
  ico_dots,
  ico_dotsVertical,
  ico_eye,
  ico_facebook,
  ico_handbag,
  ico_headset,
  ico_home,
  ico_house,
  ico_hr,
  ico_info_box,
  ico_influencer,
  ico_it_support,
  ico_licenseplate,
  ico_like,
  ico_liked,
  ico_list,
  ico_location,
  ico_logo_linkedin,
  ico_magnifier,
  ico_mail,
  ico_map,
  ico_markedcalendar,
  ico_marker,
  ico_marketing,
  ico_marketplace,
  ico_mobile_comments,
  ico_mobile_likes,
  ico_network_error,
  ico_pen,
  ico_pen_black,
  ico_people,
  ico_phone,
  ico_plus,
  ico_plus_orange,
  ico_products,
  ico_profile,
  ico_ring_bell,
  ico_sales,
  ico_share,
  ico_share_facebook,
  ico_share_linkedin,
  ico_share_orange,
  ico_share_sixt,
  ico_sixt,
  ico_smartphone,
  ico_star,
  ico_star_filled,
  ico_star_grey,
  ico_station_process,
  ico_tacho,
  ico_tech,
  ico_think_tank,
  ico_think_tank_white,
  ico_trash,
  ico_upload,
  ico_wiki,
  ico_wiki_white,
  ico_workphone,
  professionImage,
  professionImageHover,
  restaurant_logo,
  sixtbook_logo,
  wiki_icon,
  wiki_ico_tags,
  ico_write_post,
  ico_mail_wiki,
  image_placeholder,
  ico_language,
  ico_phone2,
  ico_download,
  Alexander,
  KonstantinSixt,
  NicoGabriel,
  KaiAndrejewski,
  bureaucracy_button,
  general_info,
  ico_costcenter,
  announcement,
  ico_rightMark,
  pdf_orange,
  ico_chevron_right_orange,
  ico_arrow_out,
  ico_clock,
  ico_additional_qualification,
  ico_professional_activity,
  mobile_work_icon,
  serviceHotline_banner_sixtbook,
  VinzenzPflanz,
  workday_inverted,
  our_culture,
}
