import {createMuiTheme} from '@material-ui/core'

const materialTheme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
      paperFullWidth: {
        width: '100%',
      },
      paperWidthMd: {
        maxWidth: '850px', // added px for fixed value
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '0 3.3rem 3.3rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '3.3rem',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '3.3rem',
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#FF5000',
        fontSize: '1.2rem',
        fontWeight: 'bolder',
        lineHeight: '1.8rem',
        textTransform: 'uppercase',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '2px solid #191919',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$focused': {
          border: 0,
        },
        width: '100%',
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#191919',
        },
        color: '#969696',
      },
      shrink: {
        color: '#191919',
      },
    },
    MuiListItem: {
      divider: {
        borderBottomColor: 'rgba(0,0,0,0.5)',
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: '29rem',
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: '#FF5000',
      },
      root: {
        color: '#191919',
        height: '3rem',
        width: '3rem',
      },
    },
  },
  palette: {
    primary: {
      dark: '#FF5000',
      main: '#191919',
    },
  },
  typography: {
    useNextVariants: true,
  },
})
export default materialTheme
