export const mappingtargetId = (tIds: any) => ({
  '011': tIds['Operations Germany'],
  '012': tIds['Operations Germany'],
  '013': tIds['Operations Germany'],
  '014': tIds['Operations Germany'],
  '015': tIds['Operations Germany'],
  '016': tIds['Operations Germany'],
  '017': tIds['Operations Germany'],
  '018': tIds['Operations Germany'],
  '019': tIds['Rostock / Berlin'],
  '01B': tIds['Rostock / Berlin'],
  '01E': tIds['Headquarters Pullach'],
  '01G': tIds['Headquarters Pullach'],
  '01H': tIds['Headquarters Pullach'],
  '01L': tIds['Headquarters Pullach'],
  '01M': tIds['Headquarters Pullach'],
  '01O': tIds.Austria,
  '01R': tIds['Rostock / Berlin'],
  '01T': tIds['Rostock / Berlin'],
  '01W': tIds.Austria,
  '02B': tIds.Switzerland,
  '02E': tIds.Benelux,
  '02H': tIds.Benelux,
  '02N': tIds.Benelux,
  '02S': tIds.Switzerland,
  '02U': tIds.UK,
  '02C': tIds.UK,
  '02X': tIds.Benelux,
  '031': tIds.France,
  '032': tIds.France,
  '033': tIds.France,
  '034': tIds.France,
  '035': tIds.France,
  '036': tIds.France,
  '037': tIds.France,
  '039': tIds.France,
  '03A': tIds.France,
  '03B': tIds.France,
  '03C': tIds.France,
  '03F': tIds.France,
  '03I': tIds.France,
  '03L': tIds.France,
  '03M': tIds.France,
  '03O': tIds.France,
  '03Q': tIds.France,
  '03S': tIds.France,
  '03c': tIds.France,
  '03f': tIds.France,
  '03s': tIds.France,
  '041': tIds.Spain,
  '042': tIds.Spain,
  '043': tIds.Spain,
  '044': tIds.Spain,
  '045': tIds.Spain,
  '046': tIds.Spain,
  '047': tIds.Spain,
  '048': tIds.Spain,
  '049': tIds.Spain,
  '04A': tIds.Spain,
  '04C': tIds.Spain,
  '04Z': tIds.Spain,
  '05H': tIds.USA,
  '05O': tIds.USA,
  '09H': tIds.Canada,
  '09O': tIds.Canada,
  '06D': tIds.Italy,
  '06H': tIds.Italy,
  '06O': tIds.Italy,
  '07B': tIds.Switzerland,
  '07S': tIds.Switzerland,
  '511': tIds.India,
})
