import gql from 'graphql-tag.macro'

export const newTargetGroupQuery = gql`
  {
    taxonomyTermQuery(
      offset: 0
      limit: 200
      filter: {
        conditions: [
          {operator: EQUAL, field: "vid", value: ["news_categories"]}
          {operator: IS_NOT_NULL, field: "field_filter_visible"}
          {field: "status", value: ["1!"], operator: EQUAL}
        ]
      }
    ) {
      entities {
        entityId
        entityLabel
        ... on TaxonomyTermNewsCategories {
          fieldFilterVisible
          weight
          status
          parent {
            targetId
          }
        }
      }
    }
  }
`
