/**
 * @author Kiran L
 * @email kiran.l@sixt.com
 * @create date 2019-09-13 16:03:16
 * @modify date 2019-09-13 16:03:16
 * @desc [Search bar in header which allows to search]
 */
import * as React from 'react'
import Autosuggest from 'react-autosuggest'
import {compose, withApollo} from 'react-apollo'
import {getContext} from 'recompose'
import {Link, Redirect} from 'react-router-dom'
import {targetPropTypes} from '../../commonPropsType'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import './header.scss'
import {
  getSearchHistoryQuery,
  getSearchResultsQuery,
  saveSearchHistory,
} from '../../queries/searchQuery'
import debounce from 'lodash.debounce'
import {searchTitles} from '../../constants/searchConstants'
import {SearchContext} from '../../container/Search/utils.search'
import {ACTION_TYPES} from '../../constants/articleTypesConstants'
import {groupBy, numberWithCommas} from '../../utils/genericUtils'
import {Avatar} from '@material-ui/core'
import Loader from '../common/Loader/Loader'
import find from 'lodash.find'
import {TextField} from '@material-ui/core'
import sortBy from 'lodash.sortby'

const FormatMatchHightlightText = ({
  suggestionText,
  query,
}: {
  suggestionText: string
  query: any
}) => {
  const matches = AutosuggestHighlightMatch(suggestionText, query)
  const parts = AutosuggestHighlightParse(suggestionText, matches)
  return (
    <>
      {parts.map((part: any, index: number) => {
        const className = part.highlight ? 'suggestion-highlight' : ''

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        )
      })}
    </>
  )
}

const filterSuggestion = (
  type: string,
  results: any,
  searchType: any,
  email: any = '',
  contactType: string = ''
) => {
  if (type === 'news' || type === 'socialnews' || type === 'wiki') {
    return results.map((labels: any) => ({
      id: labels._source.nodeId,
      name: labels._source.label,
      type,
    }))
  } else if (type === 'contacts') {
    let finalResult = results
    if (
      searchType === 'contacts' &&
      email &&
      contactType !== 'thinkTankAssignee'
    ) {
      finalResult = results.filter((item: any) => item._source.email !== email)
    }
    return finalResult.map((details: any) => ({
      businessPhones: details._source.businessPhones,
      email: details._source.email,
      firstName: details._source.firstName,
      lastName: details._source.lastName,
      jobTitle: details._source.jobTitle,
      type,
      userId: details._source.username,
      photoUrl: details._source.photo,
    }))
  } else if (type === 'deepLinks') {
    return results.map((labels: any) => ({
      url: labels._source.url,
      name: labels._source.label,
      urlTitle: labels._source.urlTitle,
      type,
    }))
  }
}

const renderInputComponent = (
  inputProps: any,
  materialUILayout: boolean,
  label: string = '',
  required = false
) => {
  if (materialUILayout) {
    if (label) {
      inputProps.placeholder = ''
    }
    return (
      <TextField
        label={label}
        InputLabelProps={inputProps}
        fullWidth={true}
        FormHelperTextProps={{
          className: 'helper-text',
        }}
        required={required}
        {...inputProps}
      />
    )
  }
  return <input {...inputProps} />
}

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (
  value: any,
  searchResults: any,
  searchType: string = 'all',
  email: string = '',
  contactType: string = ''
) => {
  const alteredresult = (searchResults || []).reduce(
    (acc: any, eachResult: any) => {
      if (eachResult.type !== 'history') {
        if (eachResult.results.hits.length) {
          return [
            ...acc,
            {
              details: filterSuggestion(
                eachResult.type,
                eachResult.results.hits,
                searchType,
                email,
                contactType
              ),
              showMore: eachResult.results.total,
              title: eachResult.type,
            },
          ]
        } else {
          return acc
        }
      }
      return [
        ...acc,
        {
          details: eachResult.results
            ? eachResult.results.map((history: string) => ({
                name: history,
                type: eachResult.type,
              }))
            : [],
          title: eachResult.type,
        },
      ]
    },
    []
  )
  return alteredresult
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: any) => {
  return suggestion.type === 'contacts'
    ? `${suggestion.firstName} ${suggestion.lastName}`
    : suggestion.name
}

const getSectionSuggestions = (section: any) => {
  return section && section.details
}
const handleFilter = debounce(
  async (
    val,
    client,
    dispatchToSearch,
    setLoading,
    searchType,
    isCommonUser,
    searchRef,
    email = '',
    contactType = ''
  ) => {
    setLoading(true)
    const variables = {
      filter: {},
      offset: 0,
      searchTerm: val,
      searchType:
        searchType === 'wiki'
          ? 'myWiki'
          : searchType === 'contacts'
            ? 'myContacts'
            : 'all',
      size: searchType === 'contacts' ? '1000' : '3',
      empId: '',
    }
    const results = await client.query({
      fetchPolicy: 'network-only',
      query: getSearchResultsQuery,
      variables,
    })

    const currentRef = (searchRef && searchRef.current) || null
    if (currentRef) {
      const currentView: HTMLInputElement[] = Array.from(
        currentRef.querySelectorAll('input') || []
      )
      if (currentView && currentView.length) {
        const inputValue = currentView[0].value

        if (inputValue === val) {
          setLoading(false)
          const {
            data: {searchResults},
          } = results
          dispatchToSearch({
            payload: getSuggestions(
              val,
              searchResults,
              searchType,
              email,
              contactType
            ),
            type: ACTION_TYPES.SET_SEARCH_SUGGESTIONS,
          })
        }
      }
    }
  },
  250
)

const SearchBar = (props: any) => {
  const {
    searchType = 'all',
    materialUILayout = false,
    label = '',
    email,
    required = false,
    contactType = '',
  } = props
  const {searchData, dispatchToSearch} = React.useContext(SearchContext)
  const {searchTerm, redirectSearch, suggestions} = searchData
  const [isLoading, setLoading] = React.useState(false)
  const searchRef = React.useRef<HTMLDivElement>(null)
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nValue: any
  ) => {
    const {newValue} = nValue
    dispatchToSearch({
      payload: newValue,
      type: ACTION_TYPES.SET_SEARCH_TERM,
    })
    if (newValue.length < 2) {
      onSuggestionsClearRequested()
      if (searchType === 'contacts') {
        dispatchToSearch({
          payload: {},
          type: ACTION_TYPES.SET_CONTACT_OPTION,
        })
      }
    }
  }

  const onSuggestionSelected = (event: any, options: any) => {
    if (options && options.suggestion && searchType === 'contacts') {
      dispatchToSearch({
        payload: {
          id: options.suggestion.userId,
          email: options.suggestion.email,
          firstName: options.suggestion.firstName,
          lastName: options.suggestion.lastName,
          photoUrl: options.suggestion.photoUrl,
        },
        type: ACTION_TYPES.SET_CONTACT_OPTION,
      })
    }
  }

  const onKeyDown = async (event: any) => {
    if (
      event.keyCode === 13 &&
      event.target.value.length >= 2 &&
      searchType !== 'contacts'
    ) {
      dispatchToSearch({
        payload: true,
        type: ACTION_TYPES.SET_REDIRECT,
      })
      if (searchType !== 'wiki') {
        const variables = {
          empId: props.citrixId,
          searchTerm: event.target.value.trim(),
          searchType: 'myTerms',
        }
        await props.client.mutate({
          mutation: saveSearchHistory,
          variables,
        })
      }
    }
  }
  // Use your imagination to render suggestions.
  const renderSectionTitle = (section: any) => {
    const sectionType = section.title === 'contacts' ? 'people' : section.title
    const variables = {
      empId: props.citrixId,
      searchTerm,
      searchType: 'myTerms',
    }
    return (
      section.title !== 'history' &&
      searchType !== 'contacts' &&
      section.details.length > 0 && (
        <>
          <div>{searchTitles[section.title]}</div>
          {section.showMore > 3 && (
            <span
              onClick={async () => {
                onSuggestionsClearRequested()
                if (searchType !== 'wiki') {
                  await props.client.mutate({
                    mutation: saveSearchHistory,
                    variables,
                  })
                }
              }}
            >
              <Link
                to={`/search/${sectionType}/${encodeURIComponent(searchTerm)}`}
              >{`${numberWithCommas(
                section.showMore - 3
              )} more result(s)`}</Link>
            </span>
          )}
        </>
      )
    )
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async (paramvalue: any) => {
    const {value, reason} = paramvalue
    if (
      searchType !== 'wiki' &&
      searchType !== 'contacts' &&
      searchType !== 'deepLinks' &&
      ((reason === 'input-focused' && value.length < 2) ||
        (value.length === 0 && reason === 'input-changed'))
    ) {
      const {data} = await props.client.query({
        fetchPolicy: 'network-only',
        query: getSearchHistoryQuery,
        variables: {empId: props.citrixId, searchType: 'myTerms'},
      })
      const {getMySearchHistory = {}} = data
      let {searchTerms = []} = getMySearchHistory || {}
      if (!searchTerms) {
        searchTerms = []
      }
      const historylist = [{type: 'history', results: searchTerms}]
      dispatchToSearch({
        payload: getSuggestions('', historylist),
        type: ACTION_TYPES.SET_SEARCH_HISTORY_SUGGESTIONS,
      })
    }
    if (value.length >= 2 && reason !== 'input-focused') {
      handleFilter(
        value,
        props.client,
        dispatchToSearch,
        setLoading,
        searchType,
        props.isCommonUser,
        searchRef,
        email,
        contactType
      )
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    dispatchToSearch({
      payload: [],
      type: ACTION_TYPES.SET_SEARCH_SUGGESTIONS,
    })
  }

  const renderSuggestion = (suggestion: any, {query}: any) => {
    const mobileCheck = localStorage.getItem('mobileUser')
    const isMobile = !!mobileCheck && mobileCheck === 'true'
    const NAME_SECTION = suggestion.businessPhones
      ? 'name_section_with_float'
      : 'name_section_without_float'
    if (suggestion.type === 'history') {
      return (
        <span className={'suggestion-content history'}>
          <Link
            className="name"
            to={`/search/${
              searchType === 'wiki' ? 'wiki' : 'all'
            }/${encodeURIComponent(suggestion.name)}`}
          >
            {suggestion.name}
          </Link>
        </span>
      )
    } else if (
      suggestion.type === 'news' ||
      suggestion.type === 'wiki' ||
      suggestion.type === 'socialnews'
    ) {
      return (
        <span
          className={'suggestion-content ' + suggestion.type}
          onClick={async () => {
            await props.client.mutate({
              mutation: saveSearchHistory,
              variables: {
                empId: props.citrixId,
                searchTerm: `${suggestion.id}-${suggestion.name}`,
                searchType: suggestion.type,
              },
            })
            dispatchToSearch({
              payload: '',
              type: ACTION_TYPES.SET_SEARCH_TERM,
            })
          }}
        >
          <Link
            className="name"
            to={`/${suggestion.type}/${suggestion.id}-${encodeURI(
              suggestion.name.split(' ').join('-').replaceAll('%', '-percent')
            )}`}
          >
            <FormatMatchHightlightText
              suggestionText={suggestion.name}
              query={query}
            />
          </Link>
        </span>
      )
    } else if (suggestion.type === 'contacts' && searchType !== 'contacts') {
      return (
        <div className="search-contacts-wrapper">
          <Avatar
            alt={suggestion.firstName}
            src={`data:image/png;base64,${suggestion.photoUrl}`}
            className="search-avatar-img"
          >
            {suggestion.firstName && `${suggestion.firstName.charAt(0)}`}
            {suggestion.lastName && `${suggestion.lastName.charAt(0)}`}
          </Avatar>
          <span
            className={'suggestion-content ' + suggestion.type}
            onClick={() => {
              setTimeout(() => {
                dispatchToSearch({
                  payload: '',
                  type: ACTION_TYPES.SET_SEARCH_TERM,
                })
              }, 500)
            }}
          >
            <Link
              className="contact-name"
              to={`/view-employee/${suggestion.userId}/employee-profile`}
            >
              <div
                className={isMobile ? NAME_SECTION : NAME_SECTION + ' username'}
                title={
                  (suggestion.firstName + suggestion.lastName).length > 25
                    ? `${suggestion.firstName} ${suggestion.lastName}`
                    : ''
                }
              >
                <FormatMatchHightlightText
                  suggestionText={
                    suggestion.firstName + ' ' + suggestion.lastName
                  }
                  query={query}
                />
              </div>
              {suggestion.jobTitle && !isMobile && (
                <div
                  title={
                    suggestion.jobTitle.length > 25 ? suggestion.jobTitle : ''
                  }
                  className="user-email"
                >
                  <FormatMatchHightlightText
                    suggestionText={suggestion.jobTitle}
                    query={query}
                  />
                </div>
              )}
              {suggestion.businessPhones && (
                <div
                  className={
                    isMobile
                      ? 'name_section_without_float'
                      : 'name_section_without_float user-number'
                  }
                  title={
                    suggestion.businessPhones.length > 25
                      ? suggestion.businessPhones
                      : ''
                  }
                >
                  <FormatMatchHightlightText
                    suggestionText={suggestion.businessPhones}
                    query={query}
                  />
                </div>
              )}
              {suggestion.jobTitle && isMobile && (
                <div className="email_section_mobile">
                  <FormatMatchHightlightText
                    suggestionText={suggestion.jobTitle}
                    query={query}
                  />
                </div>
              )}
            </Link>
          </span>
        </div>
      )
    } else if (searchType === 'contacts') {
      return (
        <div
          className="emp-results"
          data-testid={`search-${suggestion.userId}`}
        >
          <Avatar
            alt={suggestion.firstName}
            src={`data:image/png;base64,${suggestion.photoUrl}`}
            className="search-avatar-img"
          >
            {suggestion.firstName && `${suggestion.firstName.charAt(0)}`}
            {suggestion.lastName && `${suggestion.lastName.charAt(0)}`}
          </Avatar>
          <div
            className={isMobile ? NAME_SECTION : NAME_SECTION + ' username'}
            title={
              (suggestion.firstName + suggestion.lastName).length > 25
                ? `${suggestion.firstName} ${suggestion.lastName}`
                : ''
            }
          >
            <FormatMatchHightlightText
              suggestionText={suggestion.firstName + ' ' + suggestion.lastName}
              query={query}
            />
          </div>
        </div>
      )
    } else if (suggestion.type === 'deepLinks') {
      return (
        <span className={'suggestion-content ' + suggestion.type}>
          <Link
            className="name"
            to={{pathname: suggestion.url}}
            target="_blank"
          >
            <FormatMatchHightlightText
              suggestionText={suggestion.urlTitle}
              query={query}
            />
          </Link>
        </span>
      )
    }
  }
  const inputProps = {
    onChange,
    onKeyDown,
    placeholder:
      searchType === 'wiki' ? 'SEARCH WIKI HERE...' : 'SEARCH HERE...',
    value: searchTerm,
  }
  if (redirectSearch) {
    dispatchToSearch({
      payload: false,
      type: ACTION_TYPES.SET_REDIRECT,
    })
    return (
      <Redirect
        push={true}
        to={`/search/${
          searchType === 'wiki' ? 'wiki' : 'all'
        }/${encodeURIComponent(searchTerm)}`}
      />
    )
  }
  return (
    <div ref={searchRef} className="search-combo-box">
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        multiSection={true}
        shouldRenderSuggestions={() => true}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        renderInputComponent={inputProps =>
          renderInputComponent(inputProps, materialUILayout, label, required)
        }
        suggestions={suggestions}
        onSuggestionSelected={onSuggestionSelected}
      />
      {!!searchTerm && isLoading && <Loader isInContainer={true} />}
    </div>
  )
}

export default React.memo(
  compose(withApollo, getContext(targetPropTypes))(SearchBar)
)
