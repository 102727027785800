/**
 * MobileBottomNav - Footer of App
 * Displays Icon with link
 * Displayed only in mobile device
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import './mobileBottomNav.scss'
import SXTLiveNotificationBell from '../common/SXTNotificationBell/SXTLiveNotificationBell'
interface IObj {
  alt: string
  to: string
  className: string
  isLink?: boolean
  includeCount?: boolean
}
const navs: IObj[] = [
  {
    alt: 'News',
    className: 'ico-marked-calendar',
    to: '/home',
  },
  {
    alt: 'Wiki',
    className: 'ico-wiki',
    to: '/wiki',
  },
  {
    alt: 'App',
    className: 'ico-grid',
    to: '/work-space',
  },
  {
    alt: 'Notification',
    className: 'ico-bell',
    to: '/notification/all',
    includeCount: true,
  },
  {
    alt: 'Profile',
    className: 'ico-profile',
    to: '/profile',
  },
]

const MobileBottomNav = (props: any) => {
  return (
    <div className="mobile-bottom-nav">
      <div className="mobile-bottom-container">
        {navs &&
          navs.map((list, i) => (
            <React.Fragment key={i}>
              {list.isLink ? (
                <a
                  href={list.to}
                  className={`icon-link ${list.className}`}
                  target="blank"
                >
                  ""
                </a>
              ) : (
                <NavLink to={list.to} className={`icon-link ${list.className}`}>
                  {list.includeCount && <SXTLiveNotificationBell />}
                </NavLink>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}
export default React.memo(MobileBottomNav)
