/**
 * UserPopper - Display Profile Avatar with
 * Dropdown options ( My Profile, Bookmarks, Help,
 * Logout )
 * and redirecting router link
 *
 * @version 1.0.1
 * @author [Gopinath Bhathey Srinivasan](https://github.com/gopinath-sixt)
 */
import * as React from 'react'
import classNames from 'classnames'
import {ImageCollections} from '../../utils/imageImports'
import {Button, Fade, Paper, Popper} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import './notification.scss'
import SXTLiveNotificationBell from '../common/SXTNotificationBell/SXTLiveNotificationBell'
import NotificationPopper from './NotificationPopper'
class Notification extends React.Component<any, any> {
  public state = {showPopper: false}
  public anchorEl: any

  public handleToggle = (showPopper: boolean) => {
    this.setState({showPopper})
  }

  public render() {
    const {showPopper} = this.state
    return (
      <div className="header-notification">
        <>
          <Button
            buttonRef={node => {
              this.anchorEl = node
            }}
            className={classNames('user-btn', {active: showPopper})}
            aria-haspopup="true"
            onClick={() => this.handleToggle(true)}
            data-testid="header-notification"
          >
            <img
              src={ImageCollections.ico_bell}
              alt="Notification icon"
              className="bell-icon"
            />
            <SXTLiveNotificationBell />
          </Button>
          <Popper
            open={showPopper}
            anchorEl={this.anchorEl}
            transition={true}
            placement="bottom-end"
            disablePortal={true}
            className="header-notification-pop"
            modifiers={{
              arrow: {
                enabled: true,
              },
              flip: {
                enabled: true,
              },
              preventOverflow: {
                boundariesElement: 'scrollParent',
                enabled: true,
              },
            }}
          >
            {({TransitionProps}) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="notification-paper">
                  <span className="arrow-tip" />
                  <ClickAwayListener
                    onClickAway={() => this.handleToggle(false)}
                  >
                    <NotificationPopper handleToggle={this.handleToggle} />
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      </div>
    )
  }
}

export default React.memo(Notification)
