import gql from 'graphql-tag.macro'

const countFragment = gql`
  fragment CountFragment on Counts {
    all
    activities
    tasks
    content
    id
  }
`

export const getNotificationCountsQuery = gql`
  query ($empId: String) {
    getNotificationCounts(empId: $empId) {
      ...CountFragment
    }
  }
  ${countFragment}
`

const notificationFragment = gql`
  fragment NotificationFragment on Notification {
    typeOfNotification
    submitterCitrixId
    submittedDate
    submittedBy
    subject
    redirectionURL
    receiverCitrixId
    receivedBy
    isRead
    id
    icon
    description
    category
  }
`

export const getNotificationListQuery = gql`
  query (
    $empId: String
    $typeOfNotification: String
    $limit: Int
    $offset: Int
  ) {
    getNotificationList(
      empId: $empId
      typeOfNotification: $typeOfNotification
      limit: $limit
      offset: $offset
    ) {
      notifications {
        ...NotificationFragment
      }
      offset
      limit
      showMore
    }
  }
  ${notificationFragment}
`

export const saveAckNotificationMutation = gql`
  mutation ($empId: String, $notifications: String) {
    saveAckNotification(empId: $empId, notifications: $notifications) {
      status
      message
    }
  }
`

export const getDrupalNotifications = gql`
  query ($since: Int) {
    notifications(
      types: ["mention_created", "comment_created:comment"]
      since: $since
    ) {
      ... on NotificationMentionCreated {
        entityBundle
        entityId
        stateRead
        author: entityOwner {
          entityType
          name
          mail
          fieldFirstName
          fieldLastName
        }
        entityCreated
        sixtMention {
          entity {
            mentionOnEntityId {
              entity {
                entityIdOfComment {
                  entity {
                    entityId
                  }
                }
                fieldName
              }
            }
          }
        }
      }
      ... on NotificationCommentCreatedComment {
        entityBundle
        entityId
        stateRead
        author: entityOwner {
          entityType
          name
          mail
          fieldFirstName
          fieldLastName
        }
        entityCreated
        comment {
          entity {
            entityIdOfComment {
              entity {
                entityId
                __typename
              }
            }
            entityId
            cid
            subject
            thread
            fieldName
          }
        }
      }
    }
  }
`
export const getDrupalNotificationsCount = gql`
  query {
    notificationsCount(types: ["mention_created", "comment_created:comment"])
  }
`
export const readAllNotificationsMutation = gql`
  mutation {
    notiticationsMarkAllAsRead
  }
`
export const readNotificationMutation = gql`
  mutation ($entity_id: String!) {
    notiticationsMarkAsRead(entity_id: $entity_id)
  }
`
