/**
 * SXTNotificationBell - to display bell and number of notifications to user
 *
 * @version 1.0.1
 * @author [Karthick Shanmugam](https://github.com/karthickShanmugam0689)
 */
import * as React from 'react'
import {compose, graphql, withApollo} from 'react-apollo'
import {getContext} from 'recompose'
import {targetPropTypes} from '../../../commonPropsType'
import {
  getNotificationCountsQuery,
  getDrupalNotificationsCount,
} from '../../../queries/notificationQuery'
import NotificationCountBadge from '../NotificationCountBadge/NotificationCountBadge'
import ApolloClient from 'apollo-client'

interface IProps {
  sfCount: {
    all: number
  }
  drupCount: number
  citrixId: string
  client: ApolloClient<{query: {}}>
  isWindowActive: Boolean
}

export interface INotificationCount {
  all: number
  activities: number
  tasks: number
  content: number
  id: string
}

const SXTNotificationBell = (props: IProps) => {
  const {sfCount, drupCount} = props
  const {all = 0} = sfCount || {}
  const [count, setCount] = React.useState(all)

  React.useEffect(() => {
    const newCount = all ? all + drupCount : drupCount
    setCount(newCount)
  }, [sfCount, drupCount])
  return <NotificationCountBadge count={count} />
}

export default React.memo(
  compose(
    withApollo,
    getContext(targetPropTypes),
    graphql(getNotificationCountsQuery, {
      options: (props: any) => ({
        variables: {
          empId: props.citrixId,
        },
        notifyOnNetworkStatusChange: true,
      }),
      props: ({data: {getNotificationCounts}}: any) => {
        return {
          sfCount: getNotificationCounts,
        }
      },
    }),
    graphql(getDrupalNotificationsCount, {
      options: (props: any) => ({
        variables: {empId: props.citrixId},
        notifyOnNetworkStatusChange: true,
        pollInterval: props.isWindowActive ? 1800000 : 0, // Poll only if window is active,
      }),
      props: ({data}: any) => {
        return {
          drupCount: data.notificationsCount || 0,
        }
      },
    })
  )(SXTNotificationBell)
)
