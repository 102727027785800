import {
  sixtFbAdminURL,
  releaseNotesURL,
} from '../../constants/sixtForceConstants'

// import {sfBaseUrl} from '../../constants/sixtForceConstants' commented for go-live
// import moment from 'moment'

export const getAnchorProperties = (
  citrixId: string,
  isSixtbookSocialFeedAdmin: boolean
) => {
  const anchorProperties = [
    {
      iconName: 'ico_sixt',
      name: 'Me & Sixt',
      subMenu: [
        {
          name: 'Profile',
          to: '/profile',
        },
        // {
        //   name: 'Benefits',
        //   targetBlank: true,
        //   to:
        //     'https://sixtbook.sixt.com/wiki/human_resources/employee-benefits-mitarbeitervorteile',
        // },
        // {
        //   name: 'Travels',
        //   targetBlank: true,
        //   to:
        //     'https://sixtbook.sixt.com/wiki/human_resources/dienstreise-reisemanagement',
        // },
        // {
        //   name: 'Time',
        //   targetBlank: true,
        //   to: 'https://sixtbook.sixt.com/wiki/human_resources/zeiterfassung-ases',
        // },
        // {
        //   name: 'Training / Development',
        //   targetBlank: true,
        //   to: 'https://sixtbook.sixt.com/departments/training-2',
        // },
        // {
        //   name: 'Sixt Job Opportunities',
        //   targetBlank: true,
        //   to: 'https://www.sixt.jobs/en',
        // },
        // {
        //   name: 'Buddy Booster',
        //   targetBlank: true,
        //   to:
        //     'https://sixtbook.sixt.com/wiki/human_resources/refer-friend-buddy-booster',
        // },
      ],
    },
    {
      iconName: 'ico_wiki_white',
      name: 'wiki',
      to: '/wiki',
    },
    {
      iconName: 'ico_think_tank_white',
      name: 'Think tank',
      to: '/think-tank/tabs/home/all-ideas',
    },
    {
      iconName: 'ico_bureaucracybutton',
      name: 'Bureaucracy Button',
      to: '/bureaucracy/my-concerns',
    },
    {
      iconName: 'ico_directlyto',
      name: 'Directly To',
      to: '/directly-to/my-questions',
    },
    {
      iconName: 'announcement',
      name: 'Release Notes',
      targetBlank: true,
      to: releaseNotesURL,
    },
    // {
    //   iconName: 'ico_location',
    //   name: 'My Location',
    //   targetBlank: true,
    //   to: 'https://sixtbook.sixt.com/stations-search',
    // },
    // {
    //   iconName: 'ico_building',
    //   name: 'The Company',
    //   targetBlank: true,
    //   to: 'https://sixtbook.sixt.com/about-sixt/en/company',
    // },
    // {
    //   iconName: 'ico_tacho',
    //   name: 'Performance',
    //   targetBlank: true,
    //   to: `${sfBaseUrl}/apex/sfapp#/employeetargetdetails/${citrixId}/${moment().format(
    //     'YYYY'
    //   )}/`,
    // },
    // {
    //   iconName: 'ico_headset',
    //   name: 'Tech & IT Support',
    //   targetBlank: true,
    //   to: 'https://sixtprod.service-now.com/sp',
    // },
    // {
    //   iconName: 'ico_conversation',
    //   name: 'Feedback & Communication',
    //   targetBlank: true,
    //   to: 'https://sixtbook.sixt.com/wiki/human_resources/360%C2%B0-feedback',
    // },
    // {
    //   iconName: 'ico_handbag',
    //   name: 'Shops',
    //   targetBlank: true,
    //   to: 'https://sixtbook.sixt.com/node/8966/',
    // },
  ]
  if (isSixtbookSocialFeedAdmin) {
    anchorProperties.push({
      iconName: 'ico_sixt',
      name: 'Sixt Social Admin',
      targetBlank: true,
      to: sixtFbAdminURL,
    })
  }
  return anchorProperties
}
